import React from "react";
import {
  Button,
  TextField,
  Typography,
  Dialog,
  styled,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { IndianFlag } from "./assets";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { FcGoogle } from "react-icons/fc";
import "./LoginStyles.css";

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paperFullWidth": {
    backgroundColor: "#0F2443",
    padding: "15px",
    borderRadius: "16px",
    maxWidth: "610px",
    maxHeight: "591px",
  },
});

const CloseDiv = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
});

const CustomCloseIcon = styled(CloseIcon)({
  color: "white",
  fontSize: "26px",
  cursor: "pointer",
});

const LoginTextagain = styled(Typography)({
  color: "white",
  fontSize: "36px",
  fontStyle: "normal",
  margin: "42px 0px 50px 20px",
  fontWeight: 500,
  lineHeight: "normal",
});

const SuccessIconagain = styled(CheckCircleIcon)({
  fontSize: "160px",
  color: "green",
});

const LoginText = styled(Typography)({
  textAlign: "center",
  color: "#FFF",
  fontFamily: "Inter",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: "bold",
  lineHeight: "normal",
});

const InputPhoneNumberDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "80%",
  margin: "35px auto",
});

const OrTextDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "25px",
});

const OrText = styled(Typography)({
  fontSize: "22px",
  color: "white",
});

const LoginTextNew = styled(Typography)({
  color: "white",
  margin: "42px 0px 50px 20px",
  fontSize: "36px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
});

const SuccessIcons = styled(CheckCircleIcon)({
  fontSize: "160px",
  color: "green",
});

const EmailAcountDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "25px",
});

const EmailAcountBox = styled("div")({
  minHeight: "40px",
  width: "75%",
  borderRadius: "10px",
  padding: "10px",
  border: "3px solid #4f4c4c",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
});

const EmailAccountText = styled(Typography)({
  fontSize: "18px",
  color: "white",
  cursor: "pointer",
  fontStyle: "normal",
  fontWeight: "normal",
  lineHeight: "normal",
});

const GmailDiv = styled(Button)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "3px",
  margin: "20px auto",
  padding: "7px 12px",
  cursor: "pointer",
});

const GmailText = styled("span")({
  fontSize: "18px",
  color: "white",
});

const ContinewButton = styled(Button)({
  padding: "10px",
  backgroundColor: "#FF5C00",
  color: "white",
  width: "100%",
  fontSize: "18px",
  fontWeght: "bold",
});

const ContinewButtonMobile = styled(Button)({
  padding: "10px",
  backgroundColor: "#FF5C00",
  color: "white",
  width: "100%",
  fontSize: "16px",
});

const WithEmailTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ff8484",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#ff8484 !important",
  },
  "& .MuiInputLabel-formControl": {
    color: "white",
  },
  "& .MuiInputBase-root": {
    color: "white",
  },
});

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Inter",
    },
  },
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Login extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  renderPhoneInput() {
    return (
      <div>
        <LoginText>
          {this.state.usingMobileLogin ? "Continue using phone" : "Login"}
        </LoginText>

        <InputPhoneNumberDiv>
          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              borderBottom: "2px solid #ff5c00",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderRight: "1px solid #979797",
                paddingLeft: "60px",
              }}
            >
              <img src={IndianFlag} width={40} height={40} />
              <span
                style={{
                  fontSize: "16px",
                  marginLeft: "20px",
                  paddingRight: "5px",
                  color: "white",
                  fontWeight: "bold",
                  opacity: 0.7,
                }}
              >
                +91
              </span>
            </div>
            <div
              data-test-id="mobileNumberChangeParent"
              style={{ width: "100%" }}
            >
              <input
                value={this.state.phone}
                type="tel"
                pattern="[0-9]{10}"
                required
                data-test-id="mobileNumberChange"
                onChange={(e) => this.handleInputChange(e)}
                placeholder="Enter Mobile Number"
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  outline: "none",
                  fontSize: "16px",
                  color: "white",
                  background: "transparent",
                  paddingLeft: "26px",
                  opacity: 0.7,
                }}
                className="mobileField"
              />
            </div>
          </div>
        </InputPhoneNumberDiv>

        {!this.state.usingMobileLogin ? (
          <div>
            <OrTextDiv>
              <OrText>or</OrText>
            </OrTextDiv>
            <EmailAcountDiv
              data-test-id="haveAnAccount"
              onClick={this.haveAnAccountclick}
            >
              <EmailAcountBox>
                <EmailAccountText>Have an email account?</EmailAccountText>
              </EmailAcountBox>
            </EmailAcountDiv>
            <div className="gogle_button-container">
              {!this.state.showLoginSuccess &&
                !this.state.showLoginWithMobileSuccess && (
                  <GmailDiv
                    data-test-id="googleLogin"
                    className="google_button"
                    onClick={this.handleGoogleLogin}
                  >
                    <span style={{ marginRight: "5px" }}>
                      <FcGoogle />
                    </span>
                    <GmailText> Login with google</GmailText>
                  </GmailDiv>
                )}
            </div>
          </div>
        ) : (
          <div>
            {!this.state.showMobileOptScreen && (
              <div>
                <InputPhoneNumberDiv>
                  <ContinewButtonMobile
                    variant="contained"
                    data-test-id="mobileOTPForward"
                    onClick={this.handleMobileOtpForward}
                  >
                    Continue
                  </ContinewButtonMobile>
                </InputPhoneNumberDiv>
                <Typography
                  align="center"
                  style={{
                    maxWidth: "60%",
                    color: "white",
                    margin: "0px auto",
                  }}
                >
                  By proceeding you agree to the{" "}
                  <span
                    style={{ color: "#FF5C00" }}
                    data-test-id="span3"
                    onClick={() => window.open("TermsAndConditions", "_blank")}
                  >
                    terms of use{" "}
                  </span>{" "}
                  and{" "}
                  <span
                    style={{ color: "#FF5C00" }}
                    data-test-id="span4"
                    onClick={() => window.open("privacy-policy", "_blank")}
                  >
                    Privacy Policy{" "}
                  </span>
                </Typography>

                <div className="gogle_button-container">
                  {!this.state.showLoginSuccess &&
                    !this.state.showLoginWithMobileSuccess && (
                      <GmailDiv
                        className="google_button"
                        data-test-id="handleGoogleLogin"
                        onClick={this.handleGoogleLogin}
                      >
                        <span style={{ marginRight: "5px" }}>
                          <FcGoogle />
                        </span>
                        <GmailText> Login with google</GmailText>
                      </GmailDiv>
                    )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  renderEmailAccountInput() {
    return (
      <div>
        <LoginText>Have an email account?</LoginText>
        <InputPhoneNumberDiv>
          <div style={{ width: "90%" }}>
            <WithEmailTextField
              id="standard-basic"
              label="Enter Your Email"
              variant="standard"
              value={this.state.emailTologIn}
              fullWidth
              type="email"
              data-test-id="loginWithEmail"
              onChange={this.handleLoginWithEmailId}
              className="loginemail"
            />
            {this.state.emailValidationError && (
              <small style={{ color: "red" }}>
                {this.state.emailValidationError}
              </small>
            )}
          </div>
        </InputPhoneNumberDiv>
        {this.state.usingEmailLogin ? <div>
          <InputPhoneNumberDiv>
            <ContinewButton
              data-test-id="emailForward"
              variant="contained"
              onClick={this.handleEmailOTPForward}
            >
              Continue
            </ContinewButton>
          </InputPhoneNumberDiv>
          <Typography
            align="center"
            style={{
              maxWidth: "60%",
              color: "white",
              margin: "0px auto",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "normal",
            }}
          >
            By proceeding you agree to the{" "}
            <span
              style={{ color: "#FF5C00" }}
              data-test-id="firstSpan"
              onClick={() => window.open("TermsAndConditions", "_blank")}
            >
              terms of use{" "}
            </span>{" "}
            and{" "}
            <span
              style={{ color: "#FF5C00" }}
              data-test-id="secondSpan"
              onClick={() => window.open("privacy-policy", "_blank")}
            >
              Privacy Policy{" "}
            </span>
          </Typography>
          <div className="gogle_button-container">
            {!this.state.showLoginSuccess &&
              !this.state.showLoginWithMobileSuccess && (
                <GmailDiv
                  className="google_button"
                  onClick={this.handleGoogleLogin}
                >
                  <span style={{ marginRight: "5px" }}>
                    <FcGoogle />
                  </span>
                  <GmailText> Login with google</GmailText>
                </GmailDiv>
              )}
          </div>
        </div> :
          <div>
            <OrTextDiv>
              <OrText>or</OrText>
            </OrTextDiv>
            <EmailAcountDiv
              data-test-id="haveAPhoneNumber"
              onClick={() => this.havePhoneNumberClick()}
            >
              <EmailAcountBox>
                <EmailAccountText>Have a phone number?</EmailAccountText>
              </EmailAcountBox>
            </EmailAcountDiv>
            <div className="gogle_button-container">
              {!this.state.showLoginSuccess &&
                !this.state.showLoginWithMobileSuccess && (
                  <GmailDiv
                    data-test-id="googleLogin"
                    className="google_button"
                    onClick={this.handleGoogleLogin}
                  >
                    <span style={{ marginRight: "5px" }}>
                      <FcGoogle />
                    </span>
                    <GmailText> Login with google</GmailText>
                  </GmailDiv>
                )}
            </div>
          </div>
        }
      </div>
    );
  }

  renderEmailOTPScreen() {
    return this.state.showLoginSuccess ? (
      <>
        <div style={{ margin: "0px auto", paddingBottom: "40px" }}>
          <LoginTextNew>Login Success !</LoginTextNew>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SuccessIcons style={{ width: "225px", height: "225px" }} />
          </div>
        </div>
      </>
    ) : (
      <div>
        <div
          style={{
            textAlign: "center",
            marginBottom: "40px",
            marginTop: "10px",
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: "16px",
              fontWeight: 600,
              textAlign: "center",
              fontStyle: "normal",
              lineHeight: "normal",
            }}
          >
            Enter 4 Digit verification code sent to <br />
            <span
              style={{
                display: "block",
                color: "#FF5C00",
                marginTop: "20px",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              {this.state.emailTologIn}
            </span>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "28px",
          }}
        >
          {this.state.emailOtp.map((value: any, index: any) => (
            <input
              onKeyDown={(e) => this.handleKeydownagain(e, index)}
              autoFocus={index === 0}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 1);
              }}
              value={value}
              onChange={(e) => this.handleEmailOTPChange(e, index)}
              maxLength={1}
              pattern="\d*"
              inputMode="numeric"
              style={{
                borderRadius: "5px",
                border: "1px solid #FF5C00",
                textAlign: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                padding: "5px 0px 5px 0px",
                marginRight: "35px",
                color: "white",
                fontSize: "14px",
                width: "90px",
                height: "70px",
                outline: "none",
              }}
              id="myInput"
              data-test-id="OtpPin"
              name={index === 0 ? "otp_code" : `otp${index + 1}`}
              key={index}
              type="number"
            />
          ))}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "50px",
          }}
        >
          <div
            data-test-id="handleEmailOTPResend"
            style={{
              fontSize: "16px",
              fontWeight: 400,
              color: "white",
              cursor: "pointer",
            }}
          >
            <span>
              Didn't recive the OTP?
              {this.state.seconds === 0 ? (
                <Button
                  data-test-id="handleSignUpWithMobile"
                  style={{
                    color: "#FF5C00",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    lineHeight: "normal",
                  }}
                  onClick={() => {
                    this.handleLoginWithEmailResendAPI();
                  }}
                >
                  Resend
                </Button>
              ) : (
                <Button disabled>Resend</Button>
              )}
            </span>
          </div>
          <div>
            <div>
              <small
                style={{
                  color: "#766C6C",
                  textAlign: "center",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  lineHeight: "normal",
                }}
              >
                {this.state.seconds} Seconds
              </small>
            </div>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={this.handleLoginWithEmailOtpValidate}
              variant="contained"
              style={{
                padding: "20px",
                backgroundColor: "#FF5C00",
                color: "white",
                width: "80%",
                fontSize: "16px",
              }}
            >
              Continue
            </Button>
          </div>
          <Typography
            align="center"
            style={{
              maxWidth: "60%",
              color: "white",
              margin: "20px auto",
              cursor: "pointer",
            }}
          >
            By proceeding you agree to the{" "}
            <span
              style={{ color: "#FF5C00" }}
              data-test-id="span33"
              onClick={() => window.open("TermsAndConditions", "_blank")}
            >
              terms of use{" "}
            </span>{" "}
            and{" "}
            <span
              data-test-id="span44"
              onClick={() => window.open("TermsAndConditions", "_blank")}
              style={{ color: "#FF5C00" }}
            >
              Privacy Policy{" "}
            </span>
          </Typography>
          <div className="gogle_button-container">
            {!this.state.showLoginSuccess &&
              !this.state.showLoginWithMobileSuccess && (
                <GmailDiv
                  className="google_button"
                  onClick={this.handleGoogleLogin}
                >
                  <span style={{ marginRight: "5px" }}>
                    <FcGoogle />
                  </span>
                  <GmailText> Login with Google</GmailText>
                </GmailDiv>
              )}
          </div>
        </div>
      </div>
    );
  }

  mobileOtpTimer() {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "50px",
        }}
      >
        <div
          data-test-id="handleEmailOTPResend"
          style={{
            fontSize: "16px",
            fontWeight: 400,
            color: "white",
            cursor: "pointer",
          }}
        >
          <span>
            Didn't recive the OTP?
            {this.state.seconds === 0 ? (
              <Button
                data-test-id="handleSignUpWithMobile"
                style={{
                  color: "#FF5C00",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  lineHeight: "normal",
                }}
                onClick={() => {
                  this.handleLoginWithMobileResendAPI();
                }}
              >
                Resend
              </Button>
            ) : (
              <Button disabled>Resend</Button>
            )}
          </span>
        </div>
        <div>
          <div>
            <small
              style={{
                color: "#766C6C",
                textAlign: "center",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "normal",
                lineHeight: "normal",
              }}
            >
              {this.state.seconds} Seconds
            </small>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div>
          <CustomDialog
            data-test-id="dialogOpen"
            open={this.state.open}
            onClose={() => this.handleClose()}
            fullWidth
            maxWidth="sm"
            disableBackdropClick
          >
            <CloseDiv>
              <CustomCloseIcon onClick={this.handleClose} />
            </CloseDiv>
            {!this.state.haveEmailAccout &&
              !this.state.showMobileOptScreen &&
              !this.state.showLoginSuccess &&
              this.renderPhoneInput()}
            {this.state.haveEmailAccout &&
              !this.state.showEmailOtpScreen &&
              this.renderEmailAccountInput()}

            {this.state.showEmailOtpScreen && this.renderEmailOTPScreen()}

            {this.state.showMobileOptScreen &&
              (this.state.showLoginWithMobileSuccess ? (
                <>
                  <div style={{ margin: "0px auto", paddingBottom: "40px" }}>
                    <LoginTextagain>Login Success !</LoginTextagain>
                    <SuccessIconagain />
                  </div>
                </>
              ) : (
                <div>
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: "40px",
                      marginTop: "10px",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontSize: "16px",
                        fontWeight: 600,
                        textAlign: "center",
                        fontStyle: "normal",
                        lineHeight: "normal",
                      }}
                    >
                      Enter 4 Digit verification code sent to <br />
                      <span
                        style={{
                          display: "block",
                          color: "#FF5C00",
                          marginTop: "20px",
                          fontSize: "20px",
                          fontWeight: 600,
                        }}
                      >
                        {"+91 "}
                        {this.state.phone}
                      </span>
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "28px",
                    }}
                  >
                    {this.state.otp.map((value: any, index: any) => (
                      <input
                        data-test-id="handleOTPAgain"
                        onChange={(e) => this.handleOTPChangeagain(e, index)}
                        maxLength={1}
                        pattern="\d*"
                        inputMode="numeric"
                        style={{
                          borderRadius: "5px",
                          border: "1px solid #FF5C00",
                          textAlign: "center",
                          justifyContent: "center",
                          color: "white",
                          fontSize: "14px",
                          width: "90px",
                          height: "70px",
                          backgroundColor: "transparent",
                          padding: "5px 0px 5px 0px",
                          marginRight: "35px",
                          outline: "none",
                        }}
                        id="myInput"
                        name={index === 0 ? "otp_code" : `otp${index + 1}`}
                        key={index}
                        type="number"
                        onKeyDown={(e) => this.handleKeydownagain(e, index)}
                        autoFocus={index === 0}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 1);
                        }}
                        value={value}
                      />
                    ))}
                  </div>
                  {this.mobileOtpTimer()}
                  <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        onClick={this.handleLoginOtpValidate}
                        variant="contained"
                        style={{
                          color: "white",
                          width: "76%",
                          fontSize: "16px",
                          padding: "20px",
                          backgroundColor: "#FF5C00",
                        }}
                      >
                        Continue
                      </Button>
                    </div>
                    <Typography
                      align="center"
                      style={{
                        maxWidth: "60%",
                        color: "white",
                        margin: "20px auto",
                        cursor: "pointer",
                      }}
                    >
                      By proceeding you agree to the{" "}
                      <span
                        data-test-id="termsConditions"
                        style={{ color: "#FF5C00" }}
                        onClick={() =>
                          window.open("TermsAndConditions", "_blank")
                        }
                      >
                        terms of use{" "}
                      </span>{" "}
                      and{" "}
                      <span
                        style={{ color: "#FF5C00" }}
                        onClick={() =>
                          window.open("TermsAndConditions", "_blank")
                        }
                      >
                        Privacy Policy{" "}
                      </span>
                    </Typography>
                  </div>
                  <div className="gogle_button-container">
                    {!this.state.showLoginSuccess &&
                      !this.state.showLoginWithMobileSuccess && (
                        <GmailDiv
                          className="google_button"
                          onClick={this.handleGoogleLogin}
                        >
                          <span style={{ marginRight: "5px" }}>
                            <FcGoogle />
                          </span>
                          <GmailText> Login with Google</GmailText>
                        </GmailDiv>
                      )}
                  </div>
                </div>
              ))}
          </CustomDialog>
        </div>
      </ThemeProvider>
    );
  }
}
