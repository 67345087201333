import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    apiBody?: object;
    body?: object;
    token?: string;
    type?: string;
}

export interface ValidResponseType {
    message: string;
    data: object;
    scheduled: object;
}

interface PrivacyPolicyAttributes {
    description: string;
    created_on: string;
    updated_on: string;
}

export interface PrivacyPolicy {
    data: {
        id: string;
        type: string;
        attributes: PrivacyPolicyAttributes;
    };
}

interface ErrorResponse {
    errors: Array<{ description: string }>;
}

interface ApiResponse {
    data: PrivacyPolicy[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    userToken: string;
    loader: boolean;
    isAlert: boolean;
    alertMsg: string;
    alertType: Color;
    adminPrivacyPolicyData: PrivacyPolicy;
    isUpdate: boolean;
    policyId: string | number;
    privacyPolicy: string;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class AdminPrivacyPolicyController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    endPointApiGetPrivacyPolicy: string = "";
    showPrivacyPolicyApiCallId: string = "";
    updatePolicyApiCallIdApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationIdMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            userToken: "",
            loader: false,
            isAlert: false,
            alertMsg: "",
            alertType: configJSON.successMsg,
            adminPrivacyPolicyData: {
                data: {
                    id: "",
                    type: "",
                    attributes: {
                        description: "",
                        created_on: "",
                        updated_on: ""
                    }
                }
            },
            isUpdate: false,
            policyId: "",
            privacyPolicy: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (this.isPolicyValidResponse(responseJson)) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            };
        } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            let propsData = message.getData(getName(MessageEnum.NavigationIdMessage));
            if (propsData) {
                this.handleShowPrivacyPolicy(propsData.policyId)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        await this.getUserToken()
    }

    getUserToken = async () => {
        const token = await getStorageData('loginToken');
        this.setState({ userToken: token });
        await this.getPrivacyPolicyData()
    };

    adminStateListApiCall = (apiCallData: APIPayloadType) => {
        let { endPoint, body, method, contentType, type } = apiCallData;
        const header = {
            "Content-Type": contentType,
            "token": this.state.userToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                type === "" ? body : JSON.stringify(body)
            );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return requestMessage.messageId;
    };

    isPolicyValidResponse = (responseJson: ValidResponseType) => {
        return responseJson;
    };

    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ApiResponse & PrivacyPolicy & ErrorResponse) => {
        if (apiRequestCallId === this.endPointApiGetPrivacyPolicy) {
            this.handleGetPolicyResponse(responseJson);
        }
        if (apiRequestCallId === this.showPrivacyPolicyApiCallId) {
            this.handleShowPolicyResponse(responseJson);
        }
        if (apiRequestCallId === this.updatePolicyApiCallIdApiCallId) {
            this.handleUpdatePolicyResponse(responseJson);
        }
    };

    handleUpdatePolicyResponse = async (response: ErrorResponse) => {
        if (response.errors) {
            const errorMsg = response.errors.map((error: { description: string }) => error.description).join(', ');
            this.setState({
                isAlert: true,
                alertMsg: `${configJSON.policyUpdateErrorMsg}: ${errorMsg}`,
                alertType: configJSON.alertTypeError,
            });
        } else {
            const alertMsg = configJSON.policyUpdateSuccessMsg;
            const alertType: Color = configJSON.alertTypeSuccess;

            this.handleFieldReset();

            this.setState(
                {
                    isAlert: true,
                    alertMsg,
                    alertType,
                },
                () => {
                    this.navigationPrivacyPolicy();
                }
            );
        }
    };

    handleShowPolicyResponse = (response: PrivacyPolicy) => {
        const {
            data: {
                id,
                attributes: { description },
            },
        } = response;

        this.setState({
            loader: false,
            privacyPolicy: description,
            isUpdate: true,
            policyId: id,
        });
    };

    handleGetPolicyResponse = (response: PrivacyPolicy) => {
        this.setState({
            adminPrivacyPolicyData: response,
            loader: false,
        });
    };

    handlePrivacyPolicy = (value: string) => {
        this.setState({
            privacyPolicy: value
        });
    };

    handleFieldReset = () => {
        this.setState({ policyId: "", privacyPolicy: "" });
    };

    onCloseAlert = () => {
        this.setState({ isAlert: false });
    };

    getBlankField = (privacyPolicy: string) => {
        if (!privacyPolicy) return configJSON.descriptionText;
        return null;
    };

    handleUpdatePrivacyPolicy = async () => {
        const { privacyPolicy } = this.state;
        const blankField = this.getBlankField(privacyPolicy);

        if (blankField) {
            this.setState({
                isAlert: true,
                alertMsg: `${blankField}${configJSON.errorMsgBlankField}`,
                alertType: configJSON.alertTypeError
            });
        } else {
            let body = {
                privacy_policy: {
                    description: this.state.privacyPolicy
                }
            }
            this.updatePolicyApiCallIdApiCallId = this.adminStateListApiCall({
                contentType: configJSON.validationApiContentType,
                method: configJSON.examplePutAPiMethod,
                endPoint: `${configJSON.getPrivicyPolicyApiEndPoint}${this.state.policyId}`,
                body: body
            });
        };
    };

    handleShowPrivacyPolicy = async (selectedPolicyId: string | number) => {
        this.setState({ loader: true });
        const endPoint = `${configJSON.getPrivicyPolicyApiEndPoint}${selectedPolicyId}`;
        this.showPrivacyPolicyApiCallId = this.adminStateListApiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint
        });
    };

    getPrivacyPolicyData = async () => {
        this.setState({ loader: true });
        const endPoint = configJSON.getPolicyApiEndPoint;
        this.endPointApiGetPrivacyPolicy = this.adminStateListApiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint
        });
    };

    navigationEditPrivacyPolicy = (selectedPolicyId: string | number) => {
        const editPrivacyPolicyScreenMsg: Message = new Message(
            getName(MessageEnum.NavigationAddAdminPrivacyPolicyMessage)
        );
        editPrivacyPolicyScreenMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        editPrivacyPolicyScreenMsg.addData(getName(MessageEnum.NavigationIdMessage), { policyId: selectedPolicyId });
        this.send(editPrivacyPolicyScreenMsg);
    };

    navigationPrivacyPolicy = () => {
        const privacyPolicyScreenMsg: Message = new Message(
            getName(MessageEnum.NavigationAdminPrivacyPolicyMessage)
        );
        privacyPolicyScreenMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(privacyPolicyScreenMsg);
    };
    // Customizable Area End
}