import React from "react";
// Customizable Area Start
import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Checkbox
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import moment from "moment";
import { Logotext, Google, celender, background, IndianFlag, Success } from "./assets"
import { Steps } from "../src/Step.web"
import "./Signup.css";
// Customizable Area End

import EmailAccountRegistrationController, {
    Props,
} from "./EmailAccountRegistrationController";



const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});



export default class EmailAccountRegistration extends EmailAccountRegistrationController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End

    }


    // Customizable Area Start

    HeadingStepContent(step: number) {
        switch (step) {
            case 0:
                return <div>
                    <h3 data-test-id="StepOne" style={{ fontFamily: "Roboto, sans serif", fontSize: "24px", fontWeight: 700, color: "#000000", textAlign: "center", lineHeight: "24px", marginBottom: "10px" }}>Signup to CHSE Express</h3>
                    <p style={{ textAlign: "center", fontFamily: "Roboto, sans serif", fontSize: "18px" }}>Let’s get started</p>
                </div>

            case 1:
                return <div>
                    <h3 style={{ fontFamily: "Poppins", fontSize: "24px", fontWeight: 700, color: "#000000", textAlign: "center", lineHeight: "24px", marginBottom: "10px" }}>Signup</h3>
                    <p style={{ textAlign: "center", fontFamily: "Roboto, sans serif", fontSize: "18px" }}>Signup With Mobile Number</p>
                </div>

            case 2:
                return <div>
                    <h3 style={{ fontFamily: "Roboto, sans serif", fontSize: "24px", fontWeight: 700, color: "#000000", textAlign: "center", lineHeight: "24px", marginBottom: "10px" }}>Signup</h3>
                    <p style={{ textAlign: "center", fontFamily: "Roboto, sans serif", fontSize: "18px" }}>Email connect</p>
                </div>



        }
    }

    RegistrationForm() {
        return <div
            style={{ marginTop: '50px' }}>
            <div
                className="prl-input-right"
                style={{ marginBottom: '40px' }}
            >
                <TextField
                    data-test-id="fullNameChange"
                    id="outlined-basic"
                    label="Full Name"
                    variant="outlined"
                    fullWidth
                    value={this.state.fullName}
                    onChange={(e) => {
                        this.handleFullNameChange(e);
                    }}
                />
                <span style={{ color: 'red', fontSize: '12px' }}>
                    {this.state.fullNameErr}
                </span>
            </div>

            <div
                className="prl-input-right"
                style={{ marginBottom: '40px' }}>

                <FormControl
                    variant="outlined"
                    style={webStyle.formControl}
                    fullWidth
                >
                    <InputLabel id="demo-simple-select-outlined-label">Select state</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        data-test-id="selectState"
                        value={this.state.state}

                        fullWidth={true}
                        onChange={(e: any) => {
                            this.handleStateChange(e);
                        }}
                        label="Select state"
                        IconComponent={ExpandMoreIcon}
                    >
                        {this.state.listState.length > 0 && this.state.listState?.map((val: any, key: any) => {
                            return (
                                <MenuItem value={val.value} key={key}>{val.lable}</MenuItem>
                            );
                        })}
                    </Select>
                    <span style={{ color: 'red', fontSize: '12px' }}>
                        {this.state.stateErr}
                    </span>
                </FormControl>
            </div>

            <div
                className="prl-input-right"
                style={{
                    position: "relative",
                    marginBottom: '40px'
                }}
            >
                <FormControl variant="outlined"
                    style={webStyle.formControl}
                    fullWidth
                >
                    <InputLabel id="demo-simple-select-outlined-label">Select district</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        fullWidth={true}
                        data-test-id="selectDistrict"

                        value={this.state.District}
                        onChange={(e) => {
                            this.handleDistrictChange(e);
                        }}
                        label="Select district"
                        IconComponent={ExpandMoreIcon}
                    >
                        {this.state.listDistrict.length > 0 && this.state.listDistrict?.map((val: any, key: any) => {
                            return (
                                <MenuItem value={val.value} key={key}>{val.lable}</MenuItem>
                            );
                        })}
                    </Select>
                    <span style={{ color: 'red', fontSize: '12px' }}>
                        {this.state.districtErr}
                    </span>
                </FormControl>
            </div>


            <div style={{ display: "flex", flexDirection: "row", marginBottom: '20px' }}>
                <div className="prl-input-right-s" style={{ position: "relative", display: "flex", width: "100%" }} >

                    <div >
                        <FormControl variant="outlined"
                            style={webStyle.formControl}
                            fullWidth
                        >
                            <InputLabel id="demo-simple-select-outlined-label">Gender</InputLabel>
                            <Select

                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                fullWidth={true}
                                onChange={(e) => {
                                    this.handleGenderChange(e);
                                }}
                                data-test-id="selectGender"
                                label="Gender"
                                IconComponent={ExpandMoreIcon}
                            >

                                <MenuItem value="0" >{'Male'}</MenuItem>
                                <MenuItem value="1" >{'Female'}</MenuItem>
                                <MenuItem value="2" >{'Other'}</MenuItem>
                            </Select>
                            <span style={{ color: 'red', fontSize: '12px' }}>
                                {this.state.genderErr}
                            </span>
                        </FormControl>

                    </div>

                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <label htmlFor="dateInput">
                            <div className="prl-input-arrow1">
                                <img src={celender} style={{ width: '20px', height: '20px', color: "gray" }} />
                            </div>
                        </label>

                        <div style={{ display: "flex", width: "100%" }}>
                            {this.state.showDOB &&
                                <p data-test-id="showDOB" onClick={() => this.setState({ showDOB: false })}
                                    className="dobText"
                                    style={{
                                        position: 'absolute', background: 'white',
                                        width: '140px',
                                        height: '20px',
                                        left: '164px',
                                        marginLeft: '12px',
                                        color: 'gray',
                                        fontSize: '16px'
                                    }}>DOB</p>
                            }
                            <input
                                data-testId="txtInputDateInput"
                                data-test-id="selectDOB"
                                type="date"
                                value={this.state.date}
                                onChange={(e) => {
                                    this.handleDateChange(e);
                                }}
                                max={moment().format("YYYY-MM-DD")}
                                placeholder="DOB"
                                id="dateInput"
                                style={{
                                    width: "100%",
                                    marginLeft: "40px",
                                    maxWidth: "100%",
                                    height: "55px",
                                    boxSizing: "border-box",
                                    background: "white",
                                    color: "#000000",
                                    cursor: "pointer",
                                    border: "1px solid #c7c7c7",
                                    paddingLeft: "1.3em",
                                    fontSize: " 0.9em",
                                    fontFamily: "Inter, sans-serif",
                                    borderRadius: "0.5em",
                                }}
                                onFocus={() => this.setState({ showDOB: false })}
                            />


                        </div>
                        <span style={{ display: "flex", justifyContent: "center", color: 'red', fontSize: '12px' }}>
                            {this.state.dobError}
                        </span>
                    </div>
                </div>
            </div>

            <div className="submit-container">
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Button
                        data-test-id="handleRegister"
                        className="submit"
                        onClick={this.handleRegister}
                        disableElevation
                        variant="contained"
                        style={{
                            color: "#ffffff",
                            fontFamily: "Poppins",
                            background: "#FF5C00",
                            borderRadius: "8px",
                            width: "650px",
                            height: "62px",
                            fontSize: "18px",
                            textTransform: "none",
                            fontWeight: 600
                        }}

                    >
                        Register
                    </Button>
                </div>
                <div className="already" style={{ marginTop: "1.5em" }}>
                    <p style={{ color: 'gray' }}>Already have an account? </p>
                    <button
                        data-test-id="navigateToLogin"
                        className="su-login-btn"
                        onClick={() => {
                            this.handleLoginNavigate();
                        }}
                    >
                        Login
                    </button>
                </div>
            </div>

        </div>

    }

    MobileOTPVerification(token: any, count: number) {
        return <div>
            <div style={{ textAlign: "center", marginBottom: "50px" }}>
                <span style={{
                    textAlign: "center",
                    color: "gray",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 400,

                }}>
                    <b>Enter 4 Digit verification code send to</b> <br />
                    <span style={{
                        display: "block",
                        color: '#FF5C00',
                        textAlign: 'center',
                        fontFamily: 'Poppins',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 'bolder',
                        lineHeight: 'normal',
                        marginTop: '10px'
                    }}>{`+91 ${this.state.phone}`}</span>
                </span>
            </div>
            <div className="prl-input-right-Otp">
                {this.state.otp.map((value: any, index: any) => (
                    <input
                        id="myInput"

                        name={index == 0 ? 'otp_code' : `otp${index + 1}`}
                        key={index}
                        type="number"
                        maxLength={1}
                        pattern="\d*"
                        inputMode="numeric"
                        style={{
                            borderRadius: "8px",
                            border: "2px solid #FF5C00",
                            textAlign: "center",
                            justifyContent: "center",
                            padding: "5px 0px 5px 0px",
                            color: "#ff5c00",
                            fontSize: 14,
                            width: "23%",
                            height: "115px",
                            marginRight: "30px",
                            outline: '1px solid #FF5C00'
                        }}
                        data-test-id="enterTheMobileOTP"
                        onKeyDown={e => this.handleKeydown(e, index)}
                        autoFocus={index === 0}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                            )
                                .toString()
                                .slice(0, 1);
                        }}
                        value={value}
                        onChange={e => this.handleOTPChange(e, index)}

                    />
                ))}
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px', marginBottom: '50px' }}>
                <div style={{ fontSize: "16px", fontWeight: 400, color: "rgba(0, 0, 0, 0.47)", cursor: 'pointer' }}
                >
                    <span  >Didn't recive the OTP?
                        {count === 0 ?
                            <Button
                                onClick={() => { this.handleSignUpWithMobileResendAPI() }}
                                style={{
                                    color: '#766C6C',
                                    fontFamily: 'Poppins',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: 'normal'
                                }}
                                data-test-id="resendMobileOTP"

                            >Resend</Button> :
                            <Button disabled>Resend</Button>}
                    </span>
                </div>

                <div>
                    <small style={{
                        textAlign: 'center',
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        color: '#766C6C',

                        fontWeight: 'normal',
                        lineHeight: 'normal'
                    }}>{count} Seconds</small>
                </div>

            </div>
            <div className="submit-container">
                <div style={{ margin: '0px aut0' }}>
                    <Button
                        data-testId="txtInputNextBtn"
                        className="submit"
                        style={{
                            fontSize: "18px",
                            color: "#ffffff",
                            background: "#FF5C00",
                            borderRadius: "8px",
                            width: "376px",
                            height: "72px",

                            fontFamily: "Poppins",
                            fontStyle: 'normal',
                            textTransform: "none",
                            fontWeight: 600,
                            lineHeight: 'normal'
                        }}
                        data-test-id="signupOTPVerify"
                        onClick={() => this.handleMobileSignupOTPVerifyclick(token)}
                        disableElevation
                        variant="contained"

                    >
                        Continue
                    </Button>
                </div>
                <div className="already" style={{ marginTop: "1.5em" }}>
                    <p>Already have an account? </p>
                    <button
                        data-test-id="loginNavigate"
                        className="su-login-btn"
                        onClick={() => {
                            this.handleLoginNavigate();
                        }}
                    >
                        Login
                    </button>
                </div>
            </div>
        </div>
    }

    mobileRegistration() {
        let regex = /^\d{10}$/;
        let validMobile: any = regex.test(this.state.phone);
        return <div>
            {this.state.getOtp === false ? (
                <div>
                    <div style={{ textAlign: "center", marginBottom: "40px" }}>
                        <span style={{
                            color: 'rgba(0, 0, 0, 0.47)',
                            textAlign: 'center',
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                        }}>
                            Enter your mobile number to recieve OTP. <br />
                            This mobile number will not change later
                        </span>
                    </div>
                    <div >

                        <div style={{ width: '99%', height: '50px', display: 'flex', justifyContent: 'center', border: '1px solid gray', borderRadius: '8px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', borderRight: '1px solid gray', paddingLeft: '10px' }}>
                                <img src={IndianFlag} width={40} height={40} />
                                <span style={{ fontSize: '16px', marginLeft: '20px', paddingRight: '5px', color: 'gray', fontFamily: 'Poppins', fontWeight: 'bold' }}>+91</span>
                            </div>
                            <div style={{ width: '100%' }}>
                                <input value={this.state.phone} type="tel"
                                    pattern="[0-9]{10}"
                                    data-test-id="handleMobileChange"
                                    required
                                    onChange={(e) => this.handleInputChange(e)}
                                    placeholder="Enter Mobile Number"
                                    style={{ width: '98%', height: '100%', border: 'none', outline: 'none', fontSize: '16px', color: 'gray', paddingLeft: '10px', fontWeight: 'bolder' }}
                                    className="mobileField"
                                />
                            </div>
                        </div>

                    </div>
                    <div style={{ display: "flex", marginTop: '20px', marginBottom: '50px' }}>
                        <Checkbox
                            color="primary"
                            data-test-id="mobileTermsChecked"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            checked={this.state.mobileTermsChecked}
                            onChange={(e) => this.setState({ mobileTermsChecked: e.target.checked })}
                        />
                        <span style={{ display: "block", marginTop: "20px", cursor: 'pointer', color: 'rgba(0, 0, 0, 0.47)', fontFamily: 'Poppins', fontSize: '18px', lineHeight: 'normal', fontStyle: 'normal' }}>By Clicking,you agree to <span style={{ color: "#FF5C00" }} data-testId="txtInputNextBtn11" onClick={() => { window.location.href = 'TermsAndConditions' }}>term of use</span>
                            <br /> and <span data-test-id="mobilePrivacyPolicy" onClick={() => { window.location.href = 'privacy-policy' }} style={{ color: "#FF5C00" }}>privacy statements</span></span>
                    </div>
                    <div className="submit-container">
                        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>

                            {validMobile ?
                                <Button
                                    data-test-id="handleMobileSignup"
                                    onClick={() => this.handleMobileSignupclick(this.state.phone)}
                                    disableElevation
                                    variant="contained"
                                    style={{
                                        color: '#ffffff',
                                        fontFamily: 'Poppins',
                                        background: '#FF5C00',
                                        borderRadius: '8px',
                                        width: '650px',
                                        height: '64px',
                                        fontSize: '18px',
                                        textTransform: 'none',
                                        fontWeight: 600,
                                    }}
                                >
                                    Request OTP
                                </Button>
                                :
                                <Button
                                    data-testId="txtInputNextBtn"
                                    disableElevation
                                    disabled
                                    variant="contained"
                                    style={{
                                        color: '#000',
                                        fontFamily: 'Poppins',
                                        background: '#F0F0F0',
                                        borderRadius: '8px',
                                        width: '650px',
                                        height: '64px',
                                        fontSize: '18px',
                                        textTransform: 'none',
                                        fontWeight: 600,
                                    }}
                                >
                                    Request OTP
                                </Button>
                            }

                        </div>
                        <div className="already" style={{ marginTop: "1.5em" }}>
                            <p>Already have an account? </p>
                            <button
                                data-test-id="navigateLogin"
                                className="su-login-btn"
                                onClick={() => {
                                    this.handleLoginNavigate();
                                }}
                            >
                                Login
                            </button>
                        </div>
                    </div>
                </div >
            ) :

                this.MobileOTPVerification(this.state.signupWithMobileToken, this.state.seconds)
            }
        </div>
    }

    EmailStepper(count: number) {
        return <div>
            {this.state.activeStep === 2 && this.state.getOtp === false ? (
                <div>
                    <div style={{ textAlign: "center", marginBottom: "40px" }}>
                        <span style={{
                            color: '#0F9C26',
                            textAlign: 'center',
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal'
                        }}>
                            Enter your email id for secure connection.
                        </span>
                    </div>
                    <div className="prl-input-right">
                        <input
                            data-test-id="handleEmailInput"
                            type="text"
                            className="emailPlace"
                            value={this.state.Email}
                            required
                            onChange={(e) => {
                                this.handleEmailchange(e);
                            }}
                            style={{
                                width: '100%',
                                height: '72px',
                                borderRadius: '8px',
                                border: '1px solid #323232',
                                outline: 'none',
                                marginLeft: '0px',
                                fontSize: '16px',
                                paddingLeft: '10px'
                            }}
                            placeholder="Enter your email"
                        />
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {this.state.emailErr}
                        </span>
                    </div>
                    <div style={{ display: "flex", marginBottom: '50px' }}>
                        <Checkbox
                            color="primary"
                            data-test-id="emailTermsChecked"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            checked={this.state.emailTermsChecked}
                            onChange={(e) => this.setState({ emailTermsChecked: e.target.checked })}
                        />

                        <span style={{
                            display: "block",
                            marginTop: "20px",
                            fontFamily: "proxima nova",
                            color: 'rgba(0, 0, 0, 0.47)', fontSize: '16px'
                        }}>By Clicking,you agree to <span data-test-id="emailTermsConditions"
                            style={{ color: "#FF5C00" }} onClick={() => { window.location.href = 'TermsAndConditions' }}>
                                term of use</span><br /> and <span data-test-id="emailPrivacyPolicy" onClick={() => { window.location.href = 'privacy-policy' }} style={{ color: "#FF5C00" }}>
                                privacy statements</span></span>
                    </div>
                    <div>
                        <div style={{ width: '100%', display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: 'center' }}>
                            <Button
                                data-test-id="skipToSuccess"
                                className="submit"
                                onClick={() => this.setState({ activeStep: 3 })}
                                disableElevation
                                variant="contained"
                                style={{
                                    fontFamily: "Poppins",
                                    background: "#ECECEC",
                                    borderRadius: "8px",
                                    width: "285px",
                                    height: "75px",
                                    fontSize: "16px",
                                    textTransform: "none",
                                    fontWeight: 600,
                                    color: '#000'
                                }}

                            >
                                Skip
                            </Button>
                            <Button
                                data-test-id="handleEmailSignUp"

                                onClick={() => { this.handleEmailSignupWeb() }}
                                disableElevation
                                variant="contained"
                                style={{
                                    color: "#ffffff",
                                    fontFamily: "proxima nova",
                                    background: "#FF5C00",
                                    borderRadius: "8px",
                                    width: "260px",
                                    height: "72px",
                                    fontSize: "16px",
                                    textTransform: "none",
                                    fontWeight: 600,
                                }}
                            >
                                Verify
                            </Button>

                        </div>

                    </div>
                    <div className="already" style={{ marginTop: "1.5em" }}>
                        <p>Already have an account? </p>
                        <button
                            data-test-id="loginNavigation"
                            className="su-login-btn"
                            onClick={() => {
                                this.handleLoginNavigate();
                            }}
                        >
                            Login
                        </button>
                    </div>
                </div>
            ) : (
                <div>

                    <div style={{ marginBottom: "40px", textAlign: "center" }}>
                        <span style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            color: "gray",
                            fontWeight: 400,
                            textAlign: "center"
                        }}>
                            <b>Enter 4 Digit verification code send to your Email</b><br />
                            <span style={{ display: "block", marginTop: "20px", color: "#ff5c00", fontWeight: "bold" }}>
                                {this.state.Email}
                            </span>
                        </span>
                    </div>

                    <div className="prl-input-right-Otp">
                        {this.state.otpEmail.map((value: any, index: any) => (
                            <input
                                id="myInput"

                                name={index == 0 ? 'otp_code' : `otp${index + 1}`}
                                key={index}
                                type="number"
                                data-test-id="handleEmailOTP"
                                onKeyDown={e => this.handleKeydownEmail(e, index)}
                                autoFocus={index === 0}
                                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    e.target.value = Math.max(
                                        0,
                                        parseInt(e.target.value)
                                    )
                                        .toString()
                                        .slice(0, 1);
                                }}
                                value={value}
                                pattern="\d*"
                                onChange={e => this.handleOTPChangeEmail(e, index)}
                                maxLength={1}
                                inputMode="numeric"
                                style={{
                                    borderRadius: "8px",
                                    border: "2px solid #FF5C00",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    padding: "5px 0px 5px 0px",
                                    color: "#ff5c00",
                                    fontSize: 14,
                                    width: "23%",
                                    height: "115px",
                                    marginRight: "30px",
                                    outline: '1px solid #FF5C00'
                                }}
                            />
                        ))}
                    </div>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', marginBottom: '50px' }}>
                        <div data-test-id="handleEmailOTPResend" style={{ fontSize: "16px", fontWeight: 400, color: "rgba(0,0,0,0.47)", cursor: 'pointer' }}
                         
                        >
                            <span  >Didn't recive the OTP?
                                {count === 0 ?
                                    <Button
                                        data-test-id="handleSignUpWithMobile"
                                        style={{
                                            color: '#766C6C',
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            lineHeight: 'normal'
                                        }}
                                        onClick={() => { this.handleEmailOTPResend() }}
                                    >Resend</Button> :
                                    <Button disabled>Resend</Button>}
                            </span>
                        </div>
                        <div>
                            <div>
                                <small style={{
                                    color: '#766C6C',
                                    textAlign: 'center',
                                    fontFamily: 'Poppins',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 'normal',
                                    lineHeight: 'normal'
                                }}>{count} Seconds</small>
                            </div>
                        </div>
                    </div>


                    <div className="submit-container">
                        <div>
                            <Button
                                variant="contained"
                                style={{
                                    background: "#FF5C00",
                                    borderRadius: "8px",
                                    width: "376px",
                                    height: "72px",
                                    fontSize: "18px",
                                    color: "#ffffff",
                                    fontFamily: "Poppins",
                                    fontStyle: 'normal',
                                    textTransform: "none",
                                    fontWeight: 600,
                                    lineHeight: 'normal'
                                }}
                                data-test-id="emailOTPVerify"
                                className="submit"
                                onClick={() => { this.handleEmailSignupOTPVerifyclick() }}
                                disableElevation
                            >
                                Continue
                            </Button>
                        </div>
                        <div className="already" style={{ marginTop: "1.5em" }}>
                            <p>Already have an account? </p>
                            <button
                                className="su-login-btn"
                                data-test-id="login"
                                onClick={() => {
                                    this.handleLoginNavigate();
                                }}
                            >
                                Login
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    }

    SuccessScreen() {
        return <div>
            <div>
                <h3 style={{
                    fontFamily: "Poppins", fontSize: "36px", fontWeight: 700, color: "#000000", textAlign: "center", lineHeight: "24px",
                    marginBottom: "20px", marginTop: "10px"
                }}>Success!</h3>
                <p style={{ textAlign: "center", fontFamily: "Poppins", fontSize: "18px" }}>Registered Succesfully</p>
            </div>
            <div style={{ textAlign: "center" }}>
                <img src={Success} style={{ width: "440px", height: "440px" }} />
            </div>
            <div className="submit-container">
                <div>
                    <Button
                        data-test-id="submit"
                        className="submit"
                        onClick={() => { window.location.href = '/' }}
                        disableElevation
                        variant="contained"
                        style={{
                            color: "#ffffff",
                            fontFamily: "Poppins",
                            background: "#FF5C00",
                            borderRadius: "8px",
                            width: "376px",
                            height: "72px",
                            fontSize: "18px",
                            textTransform: "none",
                            fontWeight: 600
                        }}

                    >
                        Done
                    </Button>
                </div>
                <div className="already" style={{ marginTop: "1.5em" }}>
                    <p>Already have an account? </p>
                    <button
                        data-test-id="loginSuccess"
                        className="su-login-btn"
                        onClick={() => {
                            this.handleLoginNavigate()
                        }}
                    >
                        Login
                    </button>
                </div>
            </div>
        </div>
    }

    getStepContent(step: number) {

        switch (step) {
            case 0:
                return this.RegistrationForm();

            case 1:
                return this.mobileRegistration()

            case 2:
                return this.EmailStepper(this.state.seconds)

            case 3:
                return this.SuccessScreen()

        }
    }
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <>
                <div className="mainContainer">
                    <Grid container >
                        <Grid item xs={6} className="LeftContainer">
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                <img src={Logotext} className="ImagBckground" />
                                <img src={background} className="backgroundImag" />
                                <img src={Google} className="Google" />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ marginTop: '150px' }}>
                                <div>
                                    {this.HeadingStepContent(this.state.activeStep)}
                                </div>
                                <div>
                                    <Steps activeStep={this.state.activeStep} />
                                </div>

                                <div style={{ width: "90%", margin: "0px auto", marginTop: "35px", boxSizing: "border-box" }} className="RegisterForm">
                                    {this.getStepContent(this.state.activeStep)}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>

            </>
            // Customizable Area End

        );
    }
}


// Customizable Area Start
const webStyle = {
    formControl: {
        minWidth: 120,
        maxWidth: '100%'
    },

    selectSytle: {
        "& .MuiSelect-icon": {
            top: 'calc(50% - 18px)'
        }
    }
}

// Customizable Area End