import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}

interface HowItWorkAttributes {
  description: string;
  created_on: string;
  updated_on: string;
}

export interface HowItWork {
  data: HowItWorkData;
}

export interface HowItWorkData {
  id: string;
  type: string;
  attributes: HowItWorkAttributes;
}

interface ErrorResponse {
  errors: Array<{ token: string }>;
}

interface HowItWorkListResponse {
  data: HowItWorkData;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userToken: string;
  loader: boolean;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  HowItWork: HowItWorkData | undefined;
  isUpdate: boolean;
  aboutUsId: string | number;
  description: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class HowItWorkController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getHowItWorkListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userToken: "",
      loader: false,
      isAlert: false,
      alertMsg: "",
      alertType: configJSON.successMsg,
      isUpdate: false,
      HowItWork: undefined,
      aboutUsId: "",
      description: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }else{
        this.parseApiErrorResponse(responseJson);
      }
    } 
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getUserToken();
  }

  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    await this.getHowitWorksList();
  };

  howItWorksApiCall = (apiCallData: APIPayloadType) => {
    let { endPoint, body, method, contentType, type } = apiCallData;
    const header = {
      "Content-Type": contentType,
      token: this.state.userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? body : JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBacks = (
    apiRequestCallId: string,
    responseJson: HowItWork | HowItWorkListResponse | ErrorResponse
  ) => {
    if(apiRequestCallId === this.getHowItWorkListApiCallId) {
      this.handleGetHowItWorksResponse(responseJson as HowItWorkListResponse);
    }
  };

  handleGetHowItWorksResponse = (response: HowItWorkListResponse) => {
    this.setState({
      HowItWork: response.data,
      loader: false
    });
  };

  onCloseAlert = () => {
    this.setState({ isAlert: false });
  };
  getHowitWorksList = async () => {
    this.setState({ loader: true });
    const endPoint = configJSON.howItWorkListApiEndPoint;
    this.getHowItWorkListApiCallId = this.howItWorksApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint
    });
  };

  navigationEditHowItWorks = (aboutUsId: string | number) => {
    const editHowItWorksScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationAddHowItWorksMessage)
    );
    editHowItWorksScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    editHowItWorksScreenMsg.addData(getName(MessageEnum.NavigationIdMessage), {
      howItWorkId: aboutUsId
    });
    this.send(editHowItWorksScreenMsg);
  };
  // Customizable Area End
}
