Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.httpPutType = "PUT";
exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "bx_block_course/course_categories";
exports.SearchLanguageAPIEndPoint = "bx_block_language_options/content_languages";
exports.categoryAPIListEndPoint = "bx_block_course/course_categories/list_course_categories";
exports.subCategoryAPIEndPoint = "bx_block_course/course_sub_category";
exports.courseCategoryAPIListEndPoint = "bx_block_course/course_categories/list_categories_for_sub_category";
exports.courseBySubcategoryApiEndPoint="/bx_block_profile/courses/course_by_category/"
exports.addBundleCourseApiEndPoint="/bx_block_profile/bundle_courses/"
exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.getAllSubCategoryAPIEndPoint="bx_block_course/course_sub_category/course_sub_categories_list";
exports.childCategoryAPIEndPoint="bx_block_course/course_child_categories";
exports.langDeleteAPIEndPoint ="bx_block_language_options/content_languages";
exports.langEditAPIEndPoint ="bx_block_language_options/content_languages";
exports.statusUpdateAPIEndPoint = "/bx_block_language_options/content_languages/update_status";
exports.langCreateAPIEndPoint ="/bx_block_language_options/content_languages";
exports.courseChildCategoryAPIListEndPoint="bx_block_course/course_child_categories/list_course_child_categories";
exports.courseSubCategoryAPIListEndPoint ="bx_block_course/course_sub_category/get_sub_category_based_category?course_category_id="
exports.filterBundleCourseApiEndPoint ="/bx_block_profile/bundle_courses/search"
exports.getAllBundleCourseApiEndPoint ="/bx_block_profile/bundle_courses/";
exports.getlanguageAPIlist = "/bx_block_language_options/content_languages";
exports.getAllInstitutesAPIEndPoint ="/bx_block_profile/institutes";
exports.getStateListAPIEndPoint ="/bx_block_profile/states/show_states/1";
exports.getDistrictListAPIEndPoint ="/bx_block_profile/districts/show_districts/";
exports.tokenExpiredMessage = "Token has Expired";
exports.recordNotFoundMessage = "Record not found";
exports.bundleCourseRecordNotFoundMessage = "Record not found.";
exports.catRecordNotFoundMessage = "Record not found.";
exports.categoryStatusUpdateApiEndpoint =
  "bx_block_course/course_categories/update_status";
exports.subCategoryStatusUpdateApiEndpoint =
  "bx_block_course/course_sub_category/update_status";
exports.childCategoryStatusUpdateApiEndpoint =
  "bx_block_course/course_child_categories/update_status";
exports.msgErrorAlert = "error";
exports.msgSuccessAlert = "success";
exports.alertMsg = "Language was successfully deleted.";
exports.alertTypeError = "error";
exports.alertTypeSuccess = "success";
exports.instituteDeleteMsg = "Institute was successfully deleted.";
exports.validatinMsgName = "Input cannot be only spaces, only special characters, only numbers or characters limit should be 30max.";
exports.validationMsgAddress = "Input cannot be only spaces, only special characters, or only numbers or characters limit should be 300max..";
exports.maxFileSize = 2 * 1024 * 1024;
exports.coursesListApiEndpoint = "bx_block_profile/courses/new_lunched_courses";
exports.bannerHeadingText = "The entires CHSE syllabus at your fingertips";
exports.allCoursesText = "All Courses";
exports.applyFilterText = "Filters";
exports.ratingBtnText = 'Ratings';
exports.InstructorText = "Instructor:";
exports.Addedtext = "Added";
exports.AddToCartText = "Add to Cart";
exports.ChaptersText = "Chapters";
exports.Instructor = "INSTRUCTOR";
exports.language = "LANGUAGE";
exports.ShowMoreText = "Show More";
exports.studentCount = "6K Students";
exports.StudentNumber = "4.5";
exports.getOurInstructionWebApiEndPoint = "/account_block/instructors";
exports.getInstitutesAPIEndPoint = "/bx_block_profile/institutes";
exports.getSubjectAPIEndPoint = "/bx_block_course/course_child_categories";
exports.getEducationSubCategoryApiEndPoint = "/bx_block_course/course_sub_category/get_sub_category_based_category";
exports.SubCategoryAPiEndPoint = "bx_block_course/get_child_category_course"
exports.getFilterlanguageAPIlist = "/bx_block_language_options/content_languages";
exports.getCategoryApiEndPoint = "/bx_block_course/course_categories";
exports.getStreamApiEndPoint = "/bx_block_course/course_sub_category/list_course_sub_categories"
exports.coursesFilterApiEndPoint = "/bx_block_profile/courses/filters";
exports.wishlistApiEndpoint = "bx_block_wishlist/add_wishlist";
exports.removeWishlistApiEndpoint = "bx_block_wishlist/remove_course_from_wishlist";
exports.addToChartApiEndpoint = "bx_block_shopping_cart/add_to_cart";
exports.resetText = "Reset";
exports.applyText = "Apply";
exports.sessionExpireMsg = "Session is expired. Please login again.";

// Customizable Area End
