import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Chip,
  withStyles,
  Theme,
  createStyles
} from "@material-ui/core";
import { MdCurrencyRupee } from "react-icons/md";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import {
  PiClipboardTextLight,
  PiGraduationCap,
  PiBookOpenText,
  PiUser
} from "react-icons/pi";
import { BsDot } from "react-icons/bs";
import { FaCirclePlay, FaClock } from "react-icons/fa6";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import CourseDetailController, {
  CourseDetailsAttributes,
  Props
} from "./CourseDetailController.web";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { BiChalkboard } from "react-icons/bi";
import { IoLanguageSharp } from "react-icons/io5";
import { FiShare2 } from "react-icons/fi";
import {
  FaRegClock,
  FaTelegram,
  FaStar,
  FaWhatsappSquare,
  FaInstagramSquare,
  FaFacebook
} from "react-icons/fa";

const obj = [
  {
    label: "Instructor",
    icon: <PiUser fontSize={"inherit"} />,
    valueName: "instructor_name"
  },
  {
    label: "Chapters",
    icon: <PiBookOpenText fontSize={"inherit"} />,
    valueName: "course_class_count"
  },
  {
    label: "Lecture",
    icon: <BiChalkboard fontSize={"inherit"} />,
    valueName: "total_content"
  },
  {
    label: "Duration",
    icon: <FaRegClock fontSize={"inherit"} />,
    valueName: "all_course_class_duration"
  },
  {
    label: "Enrolled",
    icon: <PiGraduationCap fontSize={"inherit"} />,
    valueName: "student_count"
  },
  {
    label: "Languages",
    icon: <IoLanguageSharp fontSize={"inherit"} />,
    valueName: "language_name"
  }
];

// Customizable Area End

export default class CourseDetail extends CourseDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }

  renderScreen = () => {
    const value = this.state.courseDetail as CourseDetailsAttributes,
      isPurchasedOrCart = value.is_purchased || value.is_cart;
    return (
      <>
        <div style={{ ...coursesStyles.column, ...coursesStyles.leftColumn }}>
          <div style={coursesStyles.header}>
            <h1 style={{ margin: 0 }}>{value.course_name}</h1>
            <p
              style={{
                WebkitLineClamp: 3,
                height: 60,
                display: "-webkit-box",
                textOverflow: "ellipsis",
                overflow: "hidden"
              }}
            >
              {value.full_description}
            </p>
            <div style={coursesStyles.smallDescription}>
              <Chip
                label={
                  value.course_child_category &&
                  value.course_child_category.name
                }
                style={{ background: "#ff5c01", color: "white" }}
              />
              <div>
                Created by{" "}
                <strong style={{ color: "#fcb910" }}>
                  {value.instructor_name}
                </strong>
              </div>
              <div>
                <FaStar /> <span>{value.average_rating.Average_rating}</span>
              </div>
              <div>
                <FaClock /> <span>{value.all_course_class_duration} hours</span>
              </div>
            </div>
          </div>
          <StyledTabs
            value={0}
            onChange={() => {}}
            aria-label="styled tabs example"
          >
            <StyledTab label="Overview" />
            <StyledTab label="Course" />
            <StyledTab label="Instructor" />
            <StyledTab label="Review" />
          </StyledTabs>
          <h2>Course Overview</h2>
          <div
            style={{
              ...coursesStyles.column,
              ...coursesStyles.rightColumnCard
            }}
          >
            <div>
              <div
                style={{
                  display: "inline-flex",
                  gap: 10,
                  width: "100%",
                  boxSizing: "border-box",
                  padding: 15,
                  borderRadius: 12,
                  background: "white",
                  boxShadow: "0px 1px 8px 5px rgba(0, 0, 0, 0.07)"
                }}
              >
                <img
                  src={value.course_thumbnail}
                  style={{
                    borderRadius: "10.61px",
                    height: "100px",
                    width: "30%"
                  }}
                  alt="course thumbnail"
                  height={"142px"}
                  width={"auto"}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "45%"
                  }}
                >
                  <div style={coursesStyles.aboutCourse}>
                    <div
                      className="course-cat-name"
                      style={{
                        borderColor: value.course_child_category.color
                      }}
                    >
                      <CircleIcon
                        fontSize="inherit"
                        htmlColor={value.course_child_category.color}
                      />
                      <span className="course-cat-name-text">
                        <strong>
                          {value.course_child_category &&
                            value.course_child_category.name}
                        </strong>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        textAlign: "center",
                        columnGap: "10.21px",
                        alignItems: "center",
                        width: "45%"
                      }}
                    >
                      <FaStar fontSize={"inherit"} color="yellow" />
                      <span className="student-count-rating">
                        <strong style={{ color: "black" }}>
                          {value.average_rating.Average_rating}
                        </strong>{" "}
                        | {value.student_count}
                        Students
                      </span>
                    </div>
                  </div>
                  <div>
                    <strong>{value.course_name}</strong>
                  </div>
                </div>
                <div style={{ width: "25%" }}>
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      fontSize: "24px"
                    }}
                  >
                    <MdCurrencyRupee fontSize={"inherit"} />{" "}
                    <strong style={{ color: "green" }}>
                      {value.first_year_price}/-
                    </strong>
                  </div>
                </div>
              </div>
              <div>
                <h3>Instructors</h3>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr"
                  }}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      height: 60,
                      gap: 20
                    }}
                  >
                    <div>
                      <img
                        src={value.instructor_details.profile_icon}
                        height={40}
                        width={40}
                        style={{ width: 40, height: 40 }}
                      />
                    </div>
                    <div>
                      <h3 style={{ margin: 0 }}>{value.instructor_name}</h3>
                      <h5 style={{ margin: 0 }}>
                        {value.instructor_details.leacture_in}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            ...coursesStyles.column,
            ...coursesStyles.rightColumn
          }}
        >
          <div style={coursesStyles.rightColumnCard}>
            <div style={{ position: "relative" }}>
              <img
                src={value.course_thumbnail}
                style={{
                  borderRadius: "10.61px",
                  height: "142px",
                  width: "100%"
                }}
                alt="course thumbnail"
                height={"142px"}
                width={"auto"}
              />
              <FaCirclePlay
                style={{
                  position: "absolute",
                  transform: "translate(-50%, -50%)",
                  top: "50%",
                  left: "50%",
                  fontSize: 32,
                  color: "white"
                }}
                width={39}
                height={39}
              />
            </div>
            <div style={{ display: "inline-flex", gap: 10 }}>
              <div style={{ width: "50%", fontSize: "16px" }}>
                <MdCurrencyRupee fontSize={"inherit"} />{" "}
                <strong style={{ color: "green" }}>
                  {value.first_year_price}/-
                </strong>
              </div>
              {value.second_year_price && (
                <div
                  style={{
                    width: "50%",
                    fontSize: "14px",
                    color: "#BDBCBC"
                  }}
                >
                  <div
                    style={{
                      textDecorationLine: "line-through",
                      textDecorationColor: "grey"
                    }}
                  >
                    <MdCurrencyRupee fontSize={"inherit"} />{" "}
                    <span>${value.second_year_price}/-</span>
                  </div>
                  <Chip
                    label={`course validity: 01 years`}
                    style={{ fontSize: 10 }}
                  />
                </div>
              )}
            </div>
            <div style={{ ...coursesStyles.column, gap: "10px" }}>
              <Button
                variant="contained"
                style={coursesStyles.containedButton}
                fullWidth
              >
                BUY NOW
              </Button>
              <Button
                variant="outlined"
                data-test-id="addToCartTestID"
                style={coursesStyles.outlinedButton}
                fullWidth
                onClick={() => this.handleCartStatus(isPurchasedOrCart)}
              >
                {value.is_cart || value.is_purchased
                  ? "ADDED"
                  : "+ ADD TO CART"}
              </Button>
            </div>
            {this.courseDetailList(value)}
          </div>
          <div style={coursesStyles.rightColumnCard}>
            <h3 style={{ margin: 0 }}>Related Courses</h3>
            {this.state.relatedCourses &&
              this.state.relatedCourses.length > 0 &&
              this.state.relatedCourses.map(item => (
                <div>
                  <img
                    src={item.attributes.course_thumbnail}
                    style={{
                      borderRadius: "10.61px",
                      height: "142px",
                      width: "100%"
                    }}
                    alt="course thumbnail"
                    height={"142px"}
                    width={"auto"}
                  />
                  <h5>{item.attributes.course_name}</h5>
                  <span>
                    {item.attributes.course_class_count} chapters <BsDot />{" "}
                    {item.attributes.total_content} lectures
                  </span>
                </div>
              ))}
          </div>
        </div>
      </>
    );
  };

  renderView = () => {
    return (
      <div style={coursesStyles.mainLayout}>
        <Box sx={coursesStyles.screenLayout}>
          {!this.state.isLoading &&
            this.state.courseDetail &&
            this.renderScreen()}
        </Box>
      </div>
    );
  };

  courseDetailList(value: CourseDetailsAttributes) {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          {obj.map(item => (
            <div
              style={{
                display: "inline-flex",
                gap: 10,
                width: "100%",
                alignItems: "center",
                borderBottom: "1px solid #BEBEBE",
                padding: "5px 0"
              }}
            >
              <div style={{ display: "inline-flex", width: "50%", gap: "6px" }}>
                {item.icon}
                <strong>{item.label}</strong>
              </div>
              <div style={{ width: "50%" }}>
                {
                  value[
                    (item.valueName as unknown) as keyof CourseDetailsAttributes
                  ]
                }
              </div>
            </div>
          ))}
          <div
            style={{
              display: "inline-flex",
              gap: 10,
              width: "100%",
              alignItems: "center",
              borderBottom: "1px solid #BEBEBE",
              padding: "5px 0"
            }}
          >
            <div style={{ display: "inline-flex", width: "50%", gap: "6px" }}>
              <FiShare2 fontSize={"inherit"} />
              <strong>Share on</strong>
            </div>
            <div style={{ width: "50%" }}>
              <FaFacebook fontSize={"inherit"} />
              <FaInstagramSquare fontSize={"inherit"} />
              <FaWhatsappSquare fontSize={"inherit"} />
              <FaTelegram fontSize={"inherit"} />
            </div>
          </div>
        </div>
        <Paper
          style={{
            marginTop: "10px",
            padding: "4px 5px",
            display: "flex",
            alignItems: "center",
            position: "relative",
            boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.14)",
            borderRadius: 10,
            background: "#eee"
          }}
        >
          <InputBase
            style={{
              flex: 1,
              marginLeft: "10px",
              marginRight: "90px"
            }}
            readOnly
            value={window.location.href}
          />
          <Button
            onClick={() => this.copyTextToClipboard(window.location.href)}
            data-test-id="copyToClipboardTestId"
            style={{
              borderRadius: 7,
              background: "linear-gradient(133.48deg, #3cc9e1, #03859b)",
              height: 32,
              position: "absolute",
              right: 5,
              fontSize: 10,
              color: "white",
              display: "inline-flex"
            }}
            startIcon={<PiClipboardTextLight fontSize="inherit" />}
            variant="contained"
          >
            COPY
          </Button>
        </Paper>
      </>
    );
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NotificationAlertMessage
          dataTestId="alertTestId"
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        <main
          style={{
            height: "80vh",
            overflowX: "scroll"
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(180deg, rgba(60,116,129,1) 207px, rgba(255,255,255,1) 207px)",
              padding: "25px 0"
            }}
          >
            {this.renderView()}
          </div>
        </main>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
enum CourseStylesProperties {
  smallDescription = "smallDescription",
  screenLayout = "screenLayout",
  mainLayout = "mainLayout",
  leftColumn = "leftColumn",
  rightColumn = "rightColumn",
  rightColumnCard = "rightColumnCard",
  column = "column",
  header = "header",
  outlinedButton = "outlinedButton",
  containedButton = "containedButton",
  aboutCourse = "aboutCourse"
}
const coursesStyles: Record<CourseStylesProperties, React.CSSProperties> = {
  aboutCourse: {
    display: "flex",
    columnGap: "11px",
    justifyContent: "space-between",
    width: "100%"
  },
  header: {
    display: "flex",
    flexDirection: "column",
    color: "white"
  },
  screenLayout: {
    display: "inline-flex",
    gap: "20px",
    width: "100%"
  },
  mainLayout: {
    width: "100%",
    margin: "0 auto",
    maxWidth: "1024px"
  },
  column: { display: "flex", flexDirection: "column" },
  leftColumn: {
    width: "70%",
    gap: "19px"
  },
  rightColumn: {
    width: "30%",
    gap: "30px"
  },
  rightColumnCard: {
    boxSizing: "border-box",
    padding: 15,
    borderRadius: 12,
    background: "white",
    boxShadow: "0px 1px 8px 5px rgba(0, 0, 0, 0.07)",
    width: "100%",
    gap: "10px",
    display: "flex",
    flexDirection: "column"
  },
  smallDescription: {
    display: "inline-flex",
    gap: 30,
    alignItems: "center"
  },
  outlinedButton: {
    background: "white",
    color: "#FD7101",
    borderRadius: "32px",
    border: "2px solid #FD7101"
  },
  containedButton: {
    background: "linear-gradient(133.48deg, #FF5C01 -26.6%, #FFA302 118.85%)",
    color: "white",
    borderRadius: "32px"
  }
};

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
  fixed: {
    height: "58px"
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      borderRadius: "12px",
      width: "100%",
      backgroundColor: "#ffd735"
    }
  }
})((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      background: "#e3e3e3",
      color: "#212121",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      borderRadius: "0px 0px 12px 12px",
      "&.Mui-selected": {
        background: "linear-gradient(180deg, #ffed65, #fcb910)"
      },
      "&:focus": {
        opacity: 1
      }
    }
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

// Customizable Area End
