import React, { CSSProperties, Component } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import { getStorageData } from 'framework/src/Utilities';
import { styled } from '@material-ui/core';

const defaultProfilePic =
  'https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg';

const appLogo1 = require('./appLogo1.png');
const appLogo2 = require('./appLogo2.png');
const categories = require('./categories.png');
const cart = require('./shopping-cart.svg');
const heart = require('./heart.svg');
const bell = require('./bell.svg');

interface S {
  username: string;
  profilePic: string;
}

class Header extends Component<{}, S> {
  constructor() {
    super({});
    this.state = {
      username: 'USERNAME_TESTING_PURPOSE_ONLY',
      profilePic: defaultProfilePic,
    };
    this.handlerFetch = this.handlerFetch.bind(this);
  }

  async componentDidUpdate() {
    await this.fetchProfilePic();
  }

  async componentDidMount(): Promise<void> {
    window.addEventListener('storage', this.handlerFetch);
    await this.fetchProfilePic();
  }

  async handlerFetch() {
    await this.fetchProfilePic();
  }

  componentWillUnmount(): void {
    window.removeEventListener('storage', this.handlerFetch);
  }

  fetchProfilePic: () => Promise<void> = async () => {
    try {
      const username: string =
          (await getStorageData('profileUsername')) ||
          'USERNAME_TESTING_PURPOSE_ONLY',
        profilePic: string =
          (await getStorageData('profilePic')) || defaultProfilePic;
      if (username && username.split(' ')[0] !== this.state.username) {
        this.setState({
          profilePic: profilePic,
          username: username.split(' ')[0],
        });
      }
      if (profilePic !== this.state.profilePic) {
        this.setState({
          profilePic: profilePic,
        });
      }
    } catch (error) {
      if (this.state.username !== 'USERNAME_TESTING_PURPOSE_ONLY') {
        this.setState({
          username: 'USERNAME_TESTING_PURPOSE_ONLY'
        });
      }
      if (this.state.profilePic !== defaultProfilePic) {
        this.setState({ profilePic: defaultProfilePic });
      }
    }
  };

  render() {
    return (
      <nav style={{ ...webStyles.navMain, flexWrap: 'wrap' }}>
        <ul style={webStyles.listContainer}>
          <li>
            <Link style={webStyles.link} to={'/LandingPage'}>
              <img height={60} width={103} src={appLogo1} alt="Logo" />
              <img height={54} width={54} src={appLogo2} alt="Logo" />
            </Link>
          </li>
          <li className="searchWrapper">
            <Paper component="form" style={webStyles.root}>
              <IconButton style={webStyles.iconButton} aria-label="menu">
                <SearchIcon fontSize="inherit" />
              </IconButton>
              <InputBase
                style={webStyles.input}
                placeholder="Search..."
                type="text"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Paper>
          </li>
          <li style={{ marginLeft: 'auto', display: 'flex' }}>
            <Link style={webStyles.link} to={'/Categoriessubcategories'}>
              <img src={categories} width={22} height={22} alt="categories" />
              <div>CATEGORIES</div>
            </Link>
          </li>
          {this.state.username !== 'USERNAME_TESTING_PURPOSE_ONLY' ? (
            <li
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 10,
                overflow: 'hidden',
                flexWrap: 'nowrap'
              }}
            >
              <Link
                aria-controls="simple-menu"
                aria-haspopup="true"
                component={IconButonStyled}
                to={'/UserProfile'}
              >
                <img
                  style={{
                    width: 28,
                    height: 28,
                    borderRadius: 30,
                    border: '2px #F0E5FF solid'
                  }}
                  src={this.state.profilePic}
                  alt="avatar"
                />
              </Link>
              <p
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
              >
                Hi, {this.state.username}
              </p>
            </li>
          ) : (
            <>
              <li style={{ display: 'flex' }}>
                <Link
                  data-test-id="signupButton"
                  style={{ ...webStyles.linkButton, ...webStyles.signupButton }}
                  to={'/Signup'}
                >
                  SIGN UP
                </Link>
              </li>
              <li style={{ display: 'flex' }}>
                <Link
                  data-test-id="loginButton"
                  style={{ ...webStyles.linkButton, ...webStyles.signinButton }}
                  to={'/Login'}
                >
                  LOG IN
                </Link>
              </li>
            </>
          )}
          <li style={{ display: 'flex' }}>
            <img src={cart} />
          </li>
          <li style={{ display: 'flex' }}>
            <img src={heart} />
          </li>
          <li style={{ display: 'flex' }}>
            <img src={bell} />
          </li>
        </ul>
      </nav>
    );
  }
}

const IconButonStyled = styled(IconButton)({
  '&.MuiIconButton-root': {
    padding: 0,
  },
});

const webStyles: Record<string, CSSProperties> = {
  navRight: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '35px',
    zIndex: 3,
  },
  listContainer: {
    display: 'inline-flex',
    listStyle: 'none',
    width: '100%',
    gap: 15,
    flexWrap: 'nowrap',
    paddingLeft: 0,
    alignItems: 'center',
    margin: 'auto',
    maxWidth: 1330,
  },
  linkButton: {
    minWidth: 100,
    borderRadius: 30,
    fontSize: '1rem',
    cursor: 'pointer',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textDecoration: 'none',
    justifyContent: 'center',
    padding: 6,
    height: 30,
    margin: 'auto'
  },
  signupButton: {
    background: 'white'
  },
  signinButton: {
    background: '#FDC523'
  },
  link: {
    display: 'flex',
    columnGap: '12px',
    margin: 'auto',
    textDecoration: 'none',
    color: 'white'
  },
  navMain: {
    display: 'flex',
    height: '94px',
    color: 'white',
    paddingTop: '10px',
    justifyContent: 'space-between',
    background: 'linear-gradient(135deg, #3C7481 0%, #154955 100%)',
    padding: '23px 12px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.7)'
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: '30px',
    margin: 'auto',
    border: '1px solid white',
    background: '#f2f2f23b'
  },
  input: {
    flex: 1,
    marginLeft: 4,
    color: 'white',
    width: '90%'
  },
  iconButton: {
    padding: 10,
    background: 'linear-gradient(180deg,#fff54b,#f4c952)',
    fontSize: '1rem',
    margin: 3,
  },
};

export default Header;
