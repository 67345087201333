import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { apiCall } from "../../user-profile-basic/src/FooterController.web";
export const configJSON = require("./config");

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}

export interface AddCartResponse {
  message: string;
}

export interface CourseDetailResponse {
  meta: Meta;
  data: Data;
  message: string;
}

export interface RelatedCoursesResponse {
  data: Data[];
}

export interface Data {
  id: string;
  type: string;
  attributes: CourseDetailsAttributes;
}

export interface CourseDetailsAttributes {
  course_name: string;
  short_description: string;
  full_description: string;
  account_id: number;
  first_year_price: number;
  second_year_price: null;
  discounted_price: number;
  preview_video_url: string;
  created_at: Date;
  updated_at: Date;
  status: boolean;
  trending: boolean;
  what_learn: string[];
  course_sub_category: CourseSubCategory;
  course_child_category: CourseChildCategory;
  created_on: string;
  student_count: number;
  course_category: CourseCategory;
  instructor_name: string;
  instructor_details: InstructorDetails;
  language_name: string;
  total_content: number;
  average_rating: AverageRating;
  specfifc_content_duration: SpecfifcContentDuration[];
  course_thumbnail: string;
  all_course_class_duration: number;
  is_wishlist: string;
  is_purchased: boolean;
  is_cart: boolean;
  course_class_count: number;
}

export interface AverageRating {
  id: number;
  course_name: string;
  duration: null;
  year: null;
  created_at: Date;
  updated_at: Date;
  short_description: string;
  current_price: null;
  price: null;
  video_url: null;
  full_description: string;
  multiple_videos: null;
  course_category_id: number;
  valid_for_discount: null;
  course_sub_category_id: number;
  course_child_category_id: number;
  account_id: number;
  first_year_price: number;
  second_year_price: null;
  discounted_price: number;
  preview_video_url: string;
  what_learn: string[];
  status: boolean;
  trending: boolean;
  institute_id: number;
  language_options_language_id: number;
  Rating: { [key: string]: number };
  Average_rating: number;
  total_rating_count: number;
  total_review_count: number;
}

export interface CourseCategory {
  id: number;
  name: string;
}

export interface CourseChildCategory {
  id: number;
  course_category_id: number;
  course_sub_category_id: number;
  name: string;
  total_courses: null;
  total_students: null;
  status: boolean;
  color: string;
  created_at: Date;
  updated_at: Date;
}

export interface CourseSubCategory {
  id: number;
  name: string;
  course_category_id: number;
  created_at: Date;
  updated_at: Date;
  status: boolean;
  total_courses: null;
  total_subjects: null;
}

export interface InstructorDetails {
  name: string;
  details: string;
  qualifications: string;
  leacture_in: string;
  profile_icon: string;
}

export interface SpecfifcContentDuration {
  id: number;
  course_id: number;
  name: string;
  details: string;
  status: boolean;
  no_of_classes: null;
  created_at: Date;
  updated_at: Date;
  course_chapter_thumbnail: string;
  course_learning_material: string;
  class_count: number;
  duration_count: number;
  classes_data: ClassesDatum[];
}

export interface ClassesDatum {
  id: number;
  course_chapter_id: number;
  title: string;
  details: string;
  class_type: string;
  type_url: null;
  duration: string;
  status: boolean;
  featured: boolean;
  created_at: Date;
  updated_at: Date;
  video_thumbnail: string;
  video_upload: string;
}

export interface Meta {
  message: string;
}

const courseDetailTabs: unknown[] = [];

export interface Props {
  // Customizable Area Start
  navigation: {
    getParam: (param: string) => string;
  };
  id: string;
  // Customizable Area End
}

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  userToken: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  courseDetail: CourseDetailsAttributes | undefined;
  currentTab: number;
  tabs: unknown[];
  relatedCourses: Data[];
  courseId: string;
  courseType: "course" | "bundle_course";
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class UserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCourseDetailApiCallId: string = "";
  addToCartApiCallId: string = "";
  getRelatedCoursesApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.state = {
      alertMsg: "",
      alertType: "success",
      isAlert: false,
      isLoading: true,
      userToken: "",
      courseDetail: undefined,
      relatedCourses: [],
      currentTab: 0,
      tabs: courseDetailTabs,
      courseId: "0",
      courseType: "course"
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    await this.handleUserToken();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getCourseDetailApiCallId:
            this.handleGetDetailsResponse(responseJson);
            break;
          case this.addToCartApiCallId:
            this.handleCartStatusResponse(responseJson);
            break;
          case this.getRelatedCoursesApiCallId:
            this.handleGetRelatedCoursesResponse(responseJson);
            break;
        }
      } else {
        this.parseErrors(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async handleUserToken() {
    const token = await getStorageData("loginToken");
    const courseId = this.props.navigation.getParam("courseId");
    if (token && courseId) {
      this.setState({ userToken: token, courseId: courseId });
      this.getCourseDetail(courseId);
    } else {
      this.navigationLoginScreen();
    }
  }

  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  navigationLoginScreen = () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  navigationHomeScreen = () => {
    const messageHome: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    messageHome.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageHome);
  };

  async copyTextToClipboard(url: string) {
    try {
      const textToCopy = "URL copied to clipboard.";
      await navigator.clipboard.writeText(url || "");
      this.setState({
        isAlert: true,
        alertMsg: textToCopy,
        alertType: "success"
      });
    } catch (error) {
      this.setState({
        isAlert: true,
        alertMsg: "Can't copy code to clipboard",
        alertType: "error"
      });
    }
  }

  handleCartStatusResponse(response: AddCartResponse) {
    if (response && response.message) {
      this.setState({
        alertMsg: response.message,
        isAlert: true,
        alertType: "success"
      });
    }
  }

  handleCartStatus(isAdd: boolean) {
    if (isAdd) {
      this.setState({
        alertMsg: "Redirect to cart",
        isAlert: true,
        alertType: "info"
      });
      return;
    }

    const body = {
      course_id: this.state.courseId,
      no_of_year: 2,
      text: this.state.courseType
    };

    let clone: CourseDetailsAttributes = {
      ...(this.state.courseDetail as CourseDetailsAttributes)
    };
    clone.is_cart = true;

    const stateToSet: unknown = { courseDetail: clone };

    this.setState(stateToSet as Pick<S, keyof S>);
    this.addToCartApiCallId = apiCall({
      endPoint: configJSON.addToCartApiEndpoint,
      token: this.state.userToken,
      contentType: configJSON.apiContentType,
      body: body,
      method: configJSON.postMethod
    });
  }

  getCourseDetail(courseId: string) {
    this.setState({ isLoading: true });
    this.getCourseDetailApiCallId = apiCall({
      endPoint: configJSON.getCourseDetailApiEndpoint + courseId,
      method: configJSON.getMethod,
      token: this.state.userToken
    });
  }

  getRelatedCourse() {
    this.setState({ isLoading: true });
    this.getRelatedCoursesApiCallId = apiCall({
      endPoint:
        configJSON.getRelatedCoursesApiEndpoint +
        `?course_id=${this.state.courseId}&course_child_category_id=${
          this.state.courseDetail!.course_child_category.id
        }`,
      method: configJSON.getMethod,
      token: this.state.userToken
    });
  }

  handleGetRelatedCoursesResponse(responseJson: RelatedCoursesResponse) {
    if (responseJson && responseJson.data) {
      this.setState({
        relatedCourses: responseJson.data,
        isLoading: false
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  oncloseAlert() {
    this.setState({
      isAlert: false
    });
  }

  handleGetDetailsResponse(responseJson: CourseDetailResponse) {
    if (responseJson && responseJson.message) {
      this.navigationHomeScreen();
    } else if (responseJson && responseJson.data) {
      this.setState(
        {
          courseDetail: responseJson.data.attributes,
          courseType: responseJson.data.type as "course",
          isLoading: false
        },
        () => this.getRelatedCourse()
      );
    } else {
      this.setState({ isLoading: false });
    }
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
