Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";

//login

exports.loginWithMobileEndpoint = "bx_block_login/login/sent_otp_on_mobile";
exports.loginWithMobileApiMethodType = "POST"

exports.loginOtpVarificationEndPoint = "bx_block_login/login/verify_mobile_otp";

exports.loginWithemailEndpoint = "bx_block_login/login/sent_otp_on_mail";
exports.loginWithEmailOtpvarification = "bx_block_login/login/verify_email_otp";
exports.emailcheckRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

exports.deleteAccountLoginApiEndPoint = "bx_block_login/login/delete_account";
exports.deleteAccountLoginApiMethodType = "DELETE";

exports.loginWithGoodleAccountIdEndPoint = "account_block/social_sign_up_login";
exports.loginWithGoodleAccountIdMethodType = "POST";

exports.LoginWithMobileResendApiEndpoint = "account_block/resend_otp_on_mobile";
exports.LoginWithMobileResendVerifyApiEndpoint = "account_block/verify_mobile_otp";

exports.LoginWithEmailResendApiEndpoint = "account_block/resend_otp_on_email";
exports.LoginWithEmailResendVerifyApiEndpoint = "account_block/verify_mail_otp";

exports.profileInfoApiEndpoint = "bx_block_profile/profiles/user_profile";

// Customizable Area End