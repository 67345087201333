// Customizable Area Start
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Theme, Grid } from '@material-ui/core';
import CourseCardController,{ Course, Props, configJSON } from './CourseCardController.web';
import { FaCircle, FaCircleCheck, FaCirclePlay, FaHeart, FaStar } from 'react-icons/fa6';
import { LiaRupeeSignSolid } from 'react-icons/lia';
import { PiVideoFill } from 'react-icons/pi';
import { board, board2, lecturesIcon } from '../../blocks/catalogue/src/assets';
import { HiMiniClock } from 'react-icons/hi2';
import { IoLanguageOutline } from 'react-icons/io5';
import { MdCurrencyRupee } from 'react-icons/md';
import NotificationAlertMessage from './NotificationAlertMessage';

const styles = (theme: Theme) => ({
  courses :{
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    gap: 12,
    alignItems: 'center',
    position: 'relative' as const,
    zIndex: 5,
    fontFamily: 'Inter',
    padding: '0 !important',
    paddingBottom: '30px !important',
    margin: '0 !important',
    '& .heartIconList':{
      width: '24px !important'
    },
    '& p':{
        margin: 0,
        padding: 0,
        cursor: 'default'
    },
    '& .MuiGrid-grid-lg-3':{
      flexBasis: "24%",
    },
    '& .cardContainer .cardContainerList': {
      width: "100%",
      maxWidth: '314px !important',
      position: 'relative',
      backgroundColor: 'white',
      margin: '15px 0 !important',
      borderRadius: '15.073px',
      boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.27)'
    },
    '& .info': {
      height: '20px',
      justifyContent: 'center',
      '& p': {
        color: 'white',
        fontSize: '0.8rem',
      },
    },
    '& .cartPurchasedList': {
      '& p':{
      display: 'flex',
      alignItems: "center",
      justifyContent: "center"
      }
    },
    '& .imgWrapperList': {
      width: '30%',
      maxWidth: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyontent: 'center',
      alignItems: 'center',
      position: 'relative',
      borderRadius: '15.073px',
      marginRight: '10px'
    }
  },
  '@media screen and (min-width: 680px)' :{
    courses: {
      // justifyContent: 'space-between'
    }
  }
});
// Customizable Area End

class CourseCard extends CourseCardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
   // Customizable Area Start
  renderListCard = (course: Course) => {
    const value = course.attributes;
    const isPurchasedOrCart = value.is_purchased || value.is_cart;
    return (
      <div data-test-id="cardWrapperList" className="cardWrapperList">
        <div className="imgWrapperList">
          <div className="thumbnailImgList" style={{ height: 130}}>
            <img
              src={value.course_thumbnail}
              alt="course-thumbnail-image"
              className="thumbnailList"
              style={{ height: 120, width: 150}}
            />
          </div>
          <FaCirclePlay className="playIcon" color={"white"} />
        </div>
        <div className="listInfoLeft">
          <div className="listContent">
            <div className="courseHeadingList">
              <div className="courseNameList"
                style={{
                  borderBottom: `2px solid ${value.course_child_category.color}`,
                }}
              >
                <FaCircle
                  className="circleIconList"
                  color={value.course_child_category.color}
                />
                <p>{value.course_child_category.name}</p>
              </div>
              <div className="courseRatingInfoList">
                <FaStar className="starIcon starIconList" color={"#ffc108"} />
                <p>
                  {value.average_rating.Average_rating} | <span>{value.student_count || 0} Students</span>
                </p>
              </div>
              <div className="courseRatingInfoList">
                <img
                  src={board}
                  alt="icon-of-a-chalkboard"
                  className="boardIcon"
                />
                &nbsp;
                <p style={{ color: "#4C8491" }}>
                  Instructor: {value.instructor_name}
                </p>
              </div>
            </div>
            <div className="courseDescList">
              <p>{value.course_name}</p>
            </div>
          </div>

          <div className="purchaseInfoList">
            <div className="priceWrapper">
              <div className="priceList">
                <LiaRupeeSignSolid className="rupeeIconList" />
                <p>{value.first_year_price.toFixed(2)}/-</p>
              </div>
              <div className="priceList">
                <LiaRupeeSignSolid
                  className="rupeeIconList"
                  color={"#bdbcbc"}
                />
                <p>{value.second_year_price ? value.second_year_price.toFixed(2) : null}/-</p>
                <div className="stroke"></div>
              </div>
            </div>
            <div className="cartWrapper">
              <div className="wishlistIconWrapperList">
                {value.is_wishlist == "true" ? (
                  <FaHeart className="heartIconList" color={"red"} />
                ) : (
                  <FaHeart className="heartIconList" color={"DBDBDB"} />
                )}
              </div>
              <div
                className={
                  isPurchasedOrCart
                    ? "cartPurchasedList"
                    : "cartList"
                }
              >
                {isPurchasedOrCart ? (
                  <p>
                    <FaCircleCheck color={"#00BE10"} />
                    &nbsp;{configJSON.Addedtext}
                  </p>
                ) : (
                  <p>+ {configJSON.AddToCartText}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderHoveredListCard = (course: Course, index: number) => {
    const value = course.attributes;
        const isPurchasedOrCart = value.is_purchased || value.is_cart;
    return (
      <div
        style={{ display: this.state.hoveredIndex === index ? "flex" : "none" }}
        className="cardWrapperList2 cardWrapperList3"
      >
        <div className="listInfoLeft2">
          <div className="courseHeadingList, courseHeadingList2">
            <div
              style={{ marginRight: "10px" }}
              className="courseHeading2 courseHeading2List"
            >
              <p style={{ color: "#000" }}>{value.course_child_category.name}</p>
            </div>
            <div className="courseDesc3">
              <p>{value.course_name}</p>
            </div>
          </div>
          <div className="courseDescList, courseDescList2">
            <p style={{ fontWeight: 400 }}>
              {value.short_description}
            </p>
          </div>
          <div className="courseInfo courseInfoList" style={{gap: 20}}>
            <div className="info infoList">
              <PiVideoFill className="infoIconList" color={"#fff"} />
              &nbsp;
              <p>{value.total_content} Chapters</p>
            </div>
            <div className="info infoList">
              <img src={lecturesIcon} alt="a-lecture-board-icon" className="boardIcon"/>
              &nbsp;
              <p>{value.course_class_count ? value.course_class_count : "0"} Lectures</p>
            </div>
            <div className="info infoList">
              <HiMiniClock className="infoIcon infoIconList" color={"#fff"} />
              &nbsp;
              <p>{value.all_course_class_duration} Minutes</p>
            </div>
            <div className="info, infoList">
              <FaStar className="starIcon2" color={"#ffc108"} />
              &nbsp;
              <p>
              {value.average_rating.Average_rating} | <span>{value.student_count} Students</span>
              </p>
            </div>
            <div className="info, infoList">
              <img
                src={board2}
                alt="icon-of-a-chalkboard"
                className="boardIcon boardIcon2"
                style={{ width: "15px", height: "15px" }}
              />
              &nbsp;
              <p>{configJSON.Instructor}: {value.instructor_name}</p>
            </div>
            <div className="info infoList">
              <IoLanguageOutline className="infoIcon infoIconList" color={"#fff"} />
              <p>{configJSON.language}:&nbsp;</p>
              <p>{value.language_name}</p>
            </div>
          </div>
        </div>

        <div className="purchaseInfoList purchaseInfoList2">
          <div className="priceWrapper priceWrapperList">
            <div className="priceList priceList2" style={{marginTop: 12 }}>
              <LiaRupeeSignSolid className="rupeeIconList rupeeIconList2" />
              <p>{value.first_year_price.toFixed(2)}/-</p>
            </div>
            <div className="priceList priceList2">
              <LiaRupeeSignSolid
                className="rupeeIconList rupeeIconList2"
                color={"#bdbcbc"}
              />
              <p>{value.second_year_price ? value.second_year_price.toFixed(2) : null} /-</p>
              <div style={{ border: "1px solid red" }} className="stroke"></div>
            </div>
          </div>
          <div className="cartWrapper cartWrapperList">
            <div data-test-id="wishlistIcon" className="wishlistIconWrapperList wishlistIconWrapperList2" onClick={() => this.handleWishlistStatus(this.stringToBoolean(value.is_wishlist), course.id,index)}>
              {value.is_wishlist == "true" ? (
                <FaHeart
                  className="heartIconList heartIconList2"
                  color={"red"}
                />
              ) : (
                <FaHeart
                  className="heartIconList heartIconList2"
                  color={"#4C8491"}
                />
              )}
            </div>
            <div
            data-test-id="addToCartBtn"
              className={
                isPurchasedOrCart
                  ? "cartPurchasedList cartPurchasedList2"
                  : "cartList cartList2"
              }
              onClick={() => this.handleChartStatus(isPurchasedOrCart, course.id, index)}
            >
              {isPurchasedOrCart ? (
                <p style={{ color: "#316773" }}>
                  <FaCircleCheck color={"#4C8491"} />
                  &nbsp;{configJSON.Addedtext}
                </p>
              ) : (
                <p style={{ color: "#316773" }}>+ {configJSON.AddToCartText}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

// Customizable Area End

  render() {
    // Customizable Area Start
    const { classes,  gridView = true} = this.props;
    const {Courses: courses} = this.state;
    return (
        <>
        <NotificationAlertMessage
                    alertType={this.state.alertType}
                    msg={this.state.alertMsg}
                    onClose={this.oncloseAlert}
                    isOpen={this.state.isAlert}
                    data-test-id="notificationsAlertTestId"
                />
            <Grid
                container
                className={classes.courses}
                style={{ flexDirection: gridView ? "row" : "column" }}
            >
                {courses.filter(course => course.attributes.status).map((course: any, index: number) => {
                     const value = course.attributes;
                     const isPurchasedOrCart = value.is_purchased || value.is_cart;
                    if (gridView) {
                        return (
                            <Grid
                                item
                                xl={2}
                                xs={12}
                                md={3}
                                lg={3}
                                key={index}
                                className="cardContainer"
                                data-test-id="cardContainer"
                                onMouseEnter={() => this.handleMouseEnter(index)}
                                onMouseLeave={this.handleMouseLeave}
                            >
                                <div className="cardWrapper">
                                    <div className="spacingWrapper">
                                        <div className="imgWrapper">
                                            <div className="thumbnailImg">
                                                <img
                                                    src={value.course_thumbnail}
                                                    alt="course-thumbnail-image"
                                                    className="thumbnail"
                                                />
                                            </div>
                                            <FaCirclePlay className="playIcon" color={"white"} />
                                        </div>
                                        <div className="courseHeading">
                                            <div className="courseHeadingLeft">
                                                <div
                                                    className="courseName"
                                                    style={{
                                                        borderBottom: `2px solid ${value.course_child_category.color}`,
                                                    }}
                                                >
                                                    <FaCircle
                                                        className="circleIcon"
                                                        color={value.course_child_category.color}
                                                    />
                                                    <p>{value.course_child_category.name}</p>
                                                </div>
                                                <div className="courseRatingInfo">
                                                    <FaStar className="starIcon" color={"#ffc108"} size={10} />
                                                    &nbsp;
                                                    <p>
                                                    {value.average_rating.Average_rating} | <span>{value.student_count} Students</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="wishlistIconWrapper" onClick={() => this.handleWishlistStatus(this.stringToBoolean(value.is_wishlist), value.id, index)}>
                                                {value.is_wishlist == "true" ? (
                                                    <FaHeart className="heartIcon" color={"red"} />
                                                ) : (
                                                    <FaHeart className="heartIcon" color={"#DBDBDB"} />
                                                )}
                                            </div>
                                        </div>
                                        <div className="courseDesc">
                                            <p>{value.course_name}</p>
                                        </div>
                                    </div>
                                    <div
                                        className="instructor"
                                        style={{
                                          width: 'auto',
                                            backgroundColor: value.course_child_category.color,
                                            paddingLeft: 12
                                        }}
                                    >
                                        <p>{configJSON.InstructorText} {value.instructor_name}</p>
                                    </div>
                                    <div className="spacingWrapper">
                                        <div className="purchaseInfo">
                                            <div className="price">
                                                <LiaRupeeSignSolid className="rupeeIcon" />
                                                <p>{value.first_year_price}/-</p>
                                            </div>
                                            <div
                                                className={
                                                    isPurchasedOrCart ? "cartPurchased" : "cart"
                                                }
                                            >
                                                {isPurchasedOrCart ? (
                                                    <p>
                                                        <FaCircleCheck className="check" />
                                                        &nbsp; {configJSON.Addedtext}
                                                    </p>
                                                ) : (
                                                    <p>+ {configJSON.AddToCartText}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{ display: this.state.hoveredIndex === index ? "flex" : "none" }}
                                    className="cardWrapper2"
                                    data-test-id="cardWrapper2"
                                >
                                    <div className="spacingWrapper2">
                                        <div className="courseHeadingWrapper">
                                            <div className="courseHeading2">
                                                <p>{value.course_child_category.name}</p>
                                            </div>

                                            <div data-test-id="wishlistIcon" className="wishlistIconWrapperList wishlistIconWrapperList2" onClick={() => this.handleWishlistStatus(this.stringToBoolean(value.is_wishlist), course.id,index)}>
                                                {value.is_wishlist == "true" ? (
                                                    <FaHeart
                                                        className="heartIconList heartIconList2"
                                                        color={"red"}
                                                    />
                                                ) : (
                                                    <FaHeart
                                                        className="heartIconList heartIconList2"
                                                        color={"#4C8491"}
                                                    />
                                                )}
                                            </div>
                                        </div>

                                        <div className="courseDesc2">
                                            <p style={{ color: "white" }}>
                                                {value.course_name}
                                            </p>
                                        </div>

                                        <div className="shortDesc">
                                            <p>{value.short_description}</p>
                                        </div>

                                        <div className="courseInfo">
                                            <div className="courseInfoLeft">
                                                <div className="courseRatingInfo2">
                                                    <FaStar className="starIcon2" color={"#ffc108"} />
                                                    &nbsp;
                                                    <p>
                                                   {value.average_rating.Average_rating} | <span>{value.student_count} Students</span>
                                                    </p>
                                                </div>
                                                <div className="info1">
                                                    <p>{configJSON.Instructor}</p>
                                                    <p>{value.instructor_name}</p>
                                                </div>
                                                <div className="info1">
                                                    <p>{configJSON.language}</p>
                                                    <p>{value.language_name}</p>
                                                </div>
                                            </div>
                                            <div className="divider">
                                                <div className="line"></div>
                                            </div>
                                            <div className="courseInfoRight">
                                                <div className="info">
                                                    <PiVideoFill className="infoIcon" color={"#fff"} />
                                                    &nbsp;
                                                    <p>{value.total_content} {configJSON.ChaptersText}</p>
                                                </div>
                                                <div className="info">
                                                    <img className="boardIcon" src={lecturesIcon} alt="a-lecture-board-icon" />
                                                    &nbsp;
                                                    <p>{value.course_class_count ? value.course_class_count : "0"} Lectures</p>
                                                </div>
                                                <div className="info">
                                                    <HiMiniClock className="infoIcon" color={"#fff"} />
                                                    &nbsp;
                                                    <p>{value.all_course_class_duration} Minutes</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="purchaseInfo purchaseInfo2">
                                        <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        columnGap: "10px",
                                    }}>
                                    <div style={{
                                        display: "inline-flex",
                                        columnGap: "10px",
                                        alignItems: "center",
                                    }} className="course-price">
                                        <MdCurrencyRupee color="white" fontSize="inherit" />
                                        <span>
                                            {value.first_year_price}/-
                                        </span>
                                    </div>
                                    {value.second_year_price &&
                                        <div style={{
                                            display: "inline-flex",
                                            columnGap: "10px",
                                            alignItems: "center",
                                            fontSize: 16,
                                            color: "#fff"
                                        }} className="course-price-discount">
                                            <MdCurrencyRupee color="white" fontSize="inherit" />
                                            <span >
                                                {value.second_year_price}/-
                                            </span>
                                        </div>
                                    }
                                </div>
                                            <div
                                            data-test-id="addToCartBtn"
                                                className={
                                                    isPurchasedOrCart ? "cartPurchased" : "cart cart2"
                                                }
                                                onClick={() => this.handleChartStatus(isPurchasedOrCart, course.id, index)}
                                            >
                                                {isPurchasedOrCart ? (
                                                    <p>
                                                        <FaCircleCheck />
                                                        &nbsp;{configJSON.Addedtext}
                                                    </p>
                                                ) : (
                                                    <p>+ {configJSON.AddToCartText}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        )
                    } else {
                        return (
                        <Grid
                            item
                            xs={12}
                            key={index.toString()}
                            className="cardContainerList"
                            test-id="mouseEnterLeave"
                            onMouseEnter={() => this.handleMouseEnter(index)}
                            onMouseLeave={this.handleMouseLeave}
                        >
                            {this.renderListCard(course)}
                            {this.renderHoveredListCard(course, index)}
                        </Grid>
                        )
                    }
                })}
            </Grid>
        </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(styles)(CourseCard);
// Customizable Area End
