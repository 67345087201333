import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";

export interface ValidResponseType {
  message: string;
  scheduled: object;
  data: object;
}

export interface APIPayloadType {
  contentType?: string;
  apiBody?: object;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export interface HowItWork {
  data: HowItWorkData;
}


interface HowItWorkAttributes {
  created_on: string;
  description: string;
  updated_on: string;
}
export interface HowItWorkData {
  id: string;
  attributes: HowItWorkAttributes;
  type: string;
}

interface HowItWorkListResponse {
  data: HowItWorkData;
}
interface ErrorResponse {
  errors: Array<{ token: string }>;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userToken: string;
  loader: boolean;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  HowItWork: HowItWorkData | undefined;
  isUpdate: boolean;
  howItWorkId: string | number;
  description: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class HowItWorkController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  showHowItWorkApiCallId: string = "";
  submitHowItWorkApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationIdMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userToken: "",
      loader: false,
      alertType: configJSON.successMsg,
      isAlert: false,
      HowItWork: undefined,
      alertMsg: "",
      isUpdate: false,
      description: "",
      howItWorkId: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let propsData = message.getData(getName(MessageEnum.NavigationIdMessage));
      if (propsData) {
        this.handleShowHowItWorks(propsData.howItWorkId);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getUserToken();
  }

  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
  };

  howItWorksApiCall = (apiCallData: APIPayloadType) => {
    let { endPoint, body, method, contentType, type } = apiCallData;
    const header = {
      "Content-Type": contentType,
      token: this.state.userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? body : JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBacks = (
    apiRequestCallId: string,
    responseJson: HowItWork | HowItWorkListResponse | ErrorResponse
  ) => {
    switch (apiRequestCallId) {
      case this.showHowItWorkApiCallId:
        this.handleShowHowItWorkResponse(responseJson as HowItWork);
        break;
      case this.submitHowItWorkApiCallId:
        this.handleUpdateHowItWorkResponse(responseJson as ErrorResponse);
        break;
    }
  };

  handleUpdateHowItWorkResponse = async (response: ErrorResponse) => {
    if (
      response.errors &&
      response.errors.length > 0 &&
      response.errors[0].token === configJSON.invalidTokenMessage
    ) {
      this.setState({
        isAlert: true,
        alertMsg: "Invalid token",
        alertType: configJSON.alertTypeError
      });
    } else {
      const alertMsg = configJSON.howItWorksUpdateSuccessMsg;
      const alertType: Color = configJSON.alertTypeSuccess;

      this.handleFieldReset();
      this.setState(
        {
          isAlert: true,
          alertMsg,
          alertType
        },
        () => {
          this.navigationHowItWorks();
        }
      );
    }
  };

  handleShowHowItWorkResponse = (response: HowItWork) => {
    const {
      data: {
        id,
        attributes: { description }
      }
    } = response;

    this.setState({
      loader: false,
      description: description,
      isUpdate: true,
      howItWorkId: id
    });
  };

  handleGetAboutUsResponse = (response: HowItWorkListResponse) => {
    this.setState({
      HowItWork: response.data,
      loader: false
    });
  };

  handleDescriptionChange = (value: string) => {
    this.setState({
      description: value
    });
  };

  handleFieldReset = () => {
    this.setState({ description: "" });
  };

  onCloseAlert = () => {
    this.setState({ isAlert: false });
  };

  getBlankField = (description: string) => {
    if (!description) {
      return configJSON.descriptionText;
    }
    return null;
  };

  handleUpdateHowItWorks = async () => {
    const { description } = this.state;
    const blankField = this.getBlankField(description);

    if (blankField) {
      this.setState({
        isAlert: true,
        alertMsg: `${blankField}${configJSON.errorMsgBlankField}`,
        alertType: configJSON.alertTypeError
      });
    } else {
      const body = {
        how_it_work : {
          description: this.state.description
        }
      };
      this.submitHowItWorkApiCallId = this.howItWorksApiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.examplePutAPiMethod,
        endPoint: `${configJSON.howItWorkListApiEndPoint}/${
          this.state.howItWorkId
        }`,
        body: body
      });
    }
  };

  handleShowHowItWorks = async (howItWorkId: string | number) => {
    this.setState({ loader: true });
    const endPoint = `${configJSON.howItWorkListApiEndPoint}/${howItWorkId}`;
    this.showHowItWorkApiCallId = this.howItWorksApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint
    });
  };

  navigationHowItWorks = () => {
    const howItWorksScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationHowItWorksMessage)
    );
    howItWorksScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(howItWorksScreenMsg);
  };
  // Customizable Area End
}
