import React, { CSSProperties } from "react";

// Customizable Area Start
import { Box, Chip, Button, styled } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import MyCashController, { DiscountType, Props } from "./MyCashController.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { LiaRupeeSignSolid } from "react-icons/lia";
import UserSidebarMenu from "../../../components/src/UserSideBarMenu";
import { userProfileStyles } from "../../user-profile-basic/src/UserProfile.web";

// Customizable Area End

export default class MyCash extends MyCashController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.closemodal = this.closemodal.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  closemodal() {
    this.oncloseAlert();
  }

  async copyTextToClipboard(name: string, code: string | undefined) {
    try {
      const textToCopy = name + " code copied to clipboard.";
      await navigator.clipboard.writeText(code || "");
      this.setState({
        isAlert: true,
        alertMsg: textToCopy,
        alertType: "success"
      });
    } catch (error) {
      this.setState({
        isAlert: true,
        alertMsg: "Can't copy code to clipboard: " + code,
        alertType: "error"
      });
    }
  }

  renderList() {
    return (
      <div style={userProfileStyles.mainLayout}>
        <Box style={{ width: "25%" }}>
          <UserSidebarMenu
            userPic={this.state.profilePic}
            fullName={this.state.username}
            pathname={window.location.pathname}
            phoneNumber={"+" + this.state.phoneNumber}
          />
        </Box>
        <Box sx={userProfileStyles.columnLayout}>
          <h1 style={{ textAlign: "center" }}>My Cash</h1>
          <div style={webStylesLocal.cashContainer}>
            <div style={webStylesLocal.cashDisplay}>
              <div>
                <LiaRupeeSignSolid style={{ color: "black" }} />
              </div>
              <span>
                <strong>{this.state.totalAmount.toFixed(2)}</strong>
              </span>
            </div>
            <div>
              <p>Your total available My Cash units</p>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            {this.state.couponsAndDiscountsList.map(item => (
              <div style={webStylesLocal.listItem}>
                <div style={{ width: "25%" }}>
                  <Chip
                    label={item.label}
                    variant="default"
                    style={{
                      background: "#2a9e6d",
                      color: "white",
                      borderRadius: 10
                    }}
                  />
                </div>
                <div style={webStylesLocal.listItemText}>
                  <span>Date</span>
                  <span>
                    <strong>{item.get_created_at}</strong>
                  </span>
                </div>
                <div style={webStylesLocal.listItemText}>
                  <span>Amount (INR)</span>
                  <span>
                    <strong>
                      {item.discount}
                      {item.discount_type === DiscountType.Amount ? "" : "%"}
                    </strong>
                  </span>
                </div>
                <div
                  style={{
                    ...webStylesLocal.listItemText,
                    alignItems: "center",
                    width: "25%"
                  }}
                >
                  <RedeemButton
                    variant="outlined"
                    data-test-id="redeemButtonTestId"
                    onClick={async () =>
                      await this.copyTextToClipboard(
                        item.label,
                        item.coupon_code
                      )
                    }
                    style={{ borderRadius: 10 }}
                    disabled={item.is_expire || item.is_used}
                  >
                    {item.is_used ? "Redeemed" : "Redeem Now"}
                  </RedeemButton>
                  <span>
                    <strong>
                      {item.is_expire ? "Expired" : "Expires"} on{" "}
                      {item.get_expiry_date}
                    </strong>
                  </span>
                </div>
              </div>
            ))}
            <div style={{ display: "flex", padding: "1rem" }}>
              {this.state.isLoading ? (
                <CircularProgress
                  style={{ margin: "auto", color: "#2a9e6d" }}
                />
              ) : (
                <Button
                  data-test-id="loadMoreButtonTestId"
                  variant="contained"
                  style={{
                    background: "#2a9e6d",
                    margin: "auto",
                    color: "white",
                    display: this.state.loadMoreDisabled ? "none" : "unset"
                  }}
                  disabled={this.state.loadMoreDisabled || this.state.isLoading}
                  onClick={() => this.loadMore()}
                >
                  Load More
                </Button>
              )}
            </div>
          </div>
        </Box>
      </div>
    );
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        <main
          style={{
            height: "80vh",
            overflowX: "scroll",
            padding: "25px 0"
          }}
        >
          {this.renderList()}
        </main>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const RedeemButton = styled(Button)({
  border: "1px solid #FF8256",
  color: "#FF8256",
  "&:disabled": {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    color: "rgba(0, 0, 0, 0.26)"
  },
  "&.Mui-disabled": {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    color: "rgba(0, 0, 0, 0.26)"
  }
});

const webStylesLocal: Record<string, CSSProperties> = {
  bundleCourseMainLayout: {
    width: "97%",
    margin: "0 auto",
    maxWidth: "1024px"
  },
  adminLayout: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    alignItems: "center"
  },
  listItem: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1px solid lightgrey",
    boxSizing: "border-box",
    padding: "1rem 0",
    alignItems: "center"
  },
  listItemText: {
    display: "flex",
    flexDirection: "column",
    width: "25%"
  },
  cashContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  cashDisplay: {
    display: "inline-flex",
    color: "#2a9e6d",
    fontSize: "24px",
    gap: "0.2rem",
    borderRadius: "10px",
    padding: "1rem 1.5rem",
    background: "#f6f6f6",
    alignItems: "center"
  }
};
// Customizable Area End
