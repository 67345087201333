// Customizable Area Start
import React, { CSSProperties } from "react";
import { Box, Button, styled, CardContent } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MdOutlineWindow } from "react-icons/md";
import { TbLayoutList } from "react-icons/tb";
import { FaRegTrashAlt } from "react-icons/fa";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import AddIcon from "@material-ui/icons/Add";
import { MdCurrencyRupee } from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import { IoChevronDown } from "react-icons/io5";
import WishlistController, {
  Props,
  WishlistData
} from "./WishlistController.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import { study, playIcon, star } from "./assets";

const CartButton = styled(Button)(() => ({
  padding: "10px 16px",
  borderRadius: "10px",
  minHeight: "38px",
  "& .MuiButton-startIcon": {
    marginLeft: 0,
    marginRight: 4
  }
}));
const webStylesLocal: Record<string, CSSProperties> = {
  card: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(23%, 1fr))",
    gap: "10px",
    width: "100%"
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  },
  bundleCourseMainLayout: {
    width: "100%",
    margin: "0 auto",
    maxWidth: "1024px"
  },
  addToCart: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 16px",
    boxSizing: "border-box",
    margin: "auto"
  },
  addToCartList: {
    display: "flex",
    width: "20%",
    padding: "16px",
    boxSizing: "border-box",
    margin: "auto",
    alignItems: "end",
    justifyContent: "end"
  },
  instructorNameMain: {
    width: "100%",
    padding: 7,
    background: "#0E599D",
    marginBottom: "6px",
    display: "flex",
    justifyContent: "flex-start"
  },
  aboutCourse: {
    display: "flex",
    columnGap: "11px",
    marginTop: "14.3px",
    justifyContent: "space-between",
    width: "100%"
  },
  aboutCourseList: {
    display: "inline-flex",
    gap: "25px",
    width: "100%",
    alignItems: "center"
  },
  adminLayout: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    alignItems: "center"
  },
  listItem: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1px solid lightgrey",
    boxSizing: "border-box",
    padding: "1rem 0",
    alignItems: "center"
  },
  listItemText: {
    display: "flex",
    flexDirection: "column",
    width: "25%"
  },
  headerContainer: {
    display: "flex",
    color: "white",
    position: "relative",
    width: "100%"
  },
  headerImage: {
    maxWidth: 200,
    position: "absolute",
    top: "30px",
    right: 0
  },
  cashDisplay: {
    display: "inline-flex",
    color: "#2a9e6d",
    fontSize: "24px",
    gap: "0.2rem",
    borderRadius: "10px",
    padding: "1rem 1.5rem",
    background: "#f6f6f6",
    alignItems: "center"
  }
};

export default class Wishlist extends WishlistController {
  constructor(props: Props) {
    super(props);
    this.closemodal = this.closemodal.bind(this);
  }

  closemodal() {
    this.oncloseAlert();
  }

  courseList(item: WishlistData, index: number) {
    const { attributes } = item,
      value = attributes.courses.data.attributes,
      isPurchasedOrCart = value.is_purchased || value.is_cart;
    return (
      <div className="card_list">
        <div style={{ display: "inline-flex", width: "100%", height: "100%" }}>
          <CardContent style={{ width: "20%" }}>
            <div style={{ position: "relative" }}>
              <img
                src={value.course_thumbnail}
                style={{ borderRadius: "10.61px" }}
                alt="Launched Course"
                height={102}
                width={"100%"}
              />
              <img
                style={{
                  position: "absolute",
                  transform: "translate(-50%, -50%)",
                  top: "50%",
                  left: "50%"
                }}
                src={playIcon}
                alt="Launched Course"
                width={39}
                height={39}
              />
            </div>
          </CardContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              padding: "16px"
            }}
          >
            <div style={webStylesLocal.aboutCourseList}>
              <div
                className="course-cat-name"
                style={{
                  borderColor: value.course_child_category.color,
                  padding: 0
                }}
              >
                <CircleIcon
                  fontSize="inherit"
                  htmlColor={value.course_child_category.color}
                />
                <span className="course-cat-name-text">
                  <strong>
                    {value.course_child_category &&
                      value.course_child_category.name}
                  </strong>
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  columnGap: "10.21px",
                  alignItems: "center"
                }}
              >
                <img
                  alt="rate"
                  src={star}
                  height={11.71}
                  width={11.72}
                  style={{ margin: "auto" }}
                />
                <span className="student-count-rating">
                  <strong style={{ color: "black" }}>
                    {value.average_rating.Average_rating}
                  </strong>{" "}
                  | {value.student_count} Students
                </span>
              </div>
            </div>
            <div className="course-short-desc">{value.course_name}</div>
          </div>
          <div style={webStylesLocal.addToCartList}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "3px",
                alignItems: "end"
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  columnGap: "10px",
                  alignItems: "center"
                }}
                className="course-price"
              >
                <MdCurrencyRupee color="inherit" fontSize="inherit" />
                <span>{value.first_year_price}/-</span>
              </div>
              {value.second_year_price && (
                <div
                  style={{
                    display: "inline-flex",
                    columnGap: "10px",
                    alignItems: "center"
                  }}
                  className="course-price-discount"
                >
                  <MdCurrencyRupee color="inherit" fontSize="inherit" />
                  <span>{value.second_year_price}/-</span>
                </div>
              )}
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  alignItems: "center",
                  gap: "10px"
                }}
              >
                <IconButton
                  style={{
                    background: value.course_child_category.color,
                    padding: 5,
                    fontSize: 14,
                    borderRadius: 5,
                    color: "white"
                  }}
                  data-test-id="removeFromWishlistTestId"
                  onClick={() =>
                    this.removeFromWishlist(
                      item.attributes.courses.data.id,
                      index
                    )
                  }
                >
                  <FaRegTrashAlt />
                </IconButton>
                <CartButton
                  variant="contained"
                  className={
                    isPurchasedOrCart
                      ? "added-cart-btn added-cart-text"
                      : "add-cart-button add-cart-text"
                  }
                  data-test-id="addToCartTestId"
                  startIcon={
                    isPurchasedOrCart ? null : (
                      <AddIcon color="inherit" fontSize="inherit" />
                    )
                  }
                  onClick={() =>
                    this.handleAddCart(
                      isPurchasedOrCart,
                      item.attributes.courses.data.id,
                      index
                    )
                  }
                >
                  <span>{isPurchasedOrCart ? "Added" : "Add to Cart"}</span>
                </CartButton>
              </div>
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxSizing: "border-box",
                  paddingBottom: "10px"
                }}
              >
                <span style={{ fontSize: 12 }}>
                  ADDED ON <strong>{value.created_on}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  courseCard(item: WishlistData, index: number) {
    const { attributes } = item,
      value = attributes.courses.data.attributes,
      isPurchasedOrCart = value.is_purchased || value.is_cart;
    return (
      <div className="card">
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <CardContent>
            <div>
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxSizing: "border-box",
                  paddingBottom: "10px"
                }}
              >
                <span style={{ fontSize: 12 }}>
                  ADDED ON <strong>{value.created_on}</strong>
                </span>
                <IconButton
                  style={{
                    background: value.course_child_category.color,
                    padding: 5,
                    fontSize: 14,
                    borderRadius: 5,
                    color: "white"
                  }}
                  data-test-id="removeFromWishlistTestId"
                  onClick={() =>
                    this.removeFromWishlist(
                      item.attributes.courses.data.id,
                      index
                    )
                  }
                >
                  <FaRegTrashAlt />
                </IconButton>
              </div>
              <div style={{ position: "relative" }}>
                <img
                  src={value.course_thumbnail}
                  style={{ borderRadius: "10.61px" }}
                  alt="Launched Course"
                  height={102}
                  width={"100%"}
                />
                <img
                  style={{
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    top: "50%",
                    left: "50%"
                  }}
                  src={playIcon}
                  alt="Launched Course"
                  width={39}
                  height={39}
                />
              </div>
            </div>

            <div className="course-short-desc">{value.course_name}</div>
          </CardContent>
          <div
            style={{
              marginTop: "1px",
              justifyContent: "space-between"
            }}
          >
            <div
              style={{
                ...webStylesLocal.instructorNameMain,
                boxSizing: "border-box",
                background: value.course_child_category.color
              }}
            >
              <div className="instructor-name">
                Instructor : {value.instructor_name}
              </div>
            </div>
          </div>
          <div style={webStylesLocal.addToCart}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                columnGap: "10px"
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  columnGap: "10px",
                  alignItems: "center"
                }}
                className="course-price"
              >
                <MdCurrencyRupee color="inherit" fontSize="inherit" />
                <span>{value.first_year_price}/-</span>
              </div>
              {value.second_year_price && (
                <div
                  style={{
                    display: "inline-flex",
                    columnGap: "10px",
                    alignItems: "center"
                  }}
                  className="course-price-discount"
                >
                  <MdCurrencyRupee color="inherit" fontSize="inherit" />
                  <span>{value.second_year_price}/-</span>
                </div>
              )}
            </div>
            <CartButton
              variant="contained"
              className={
                isPurchasedOrCart
                  ? "added-cart-btn added-cart-text"
                  : "add-cart-button add-cart-text"
              }
              startIcon={
                isPurchasedOrCart ? null : (
                  <AddIcon color="inherit" fontSize="inherit" />
                )
              }
              data-test-id="addToCartTestId"
              onClick={() =>
                this.handleAddCart(
                  isPurchasedOrCart,
                  item.attributes.courses.data.id,
                  index
                )
              }
            >
              <span>{isPurchasedOrCart ? "Added" : "Add to Cart"}</span>
            </CartButton>
          </div>
        </div>
      </div>
    );
  }

  renderList() {
    return (
      <div style={webStylesLocal.bundleCourseMainLayout}>
        <Box sx={webStylesLocal.adminLayout}>
          <div style={webStylesLocal.headerContainer}>
            <div style={{ maxWidth: "800px", marginRight: "auto" }}>
              <h1>My Wishlist</h1>
              <p>
                Save and curate your desired courses, and easily plan your
                learning journey and access to your favorite educational
                resources with just a click.
              </p>
            </div>
            <div style={webStylesLocal.headerImage}>
              <img
                src={study}
                alt=""
                width={200}
                height={80}
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
          <div
            style={{
              display: "inline-flex",
              width: "100%",
              justifyContent: "flex-end",
              marginTop: "30px",
              gap: "10px"
            }}
          >
            <IconButton
              style={{
                backgroundColor:
                  this.state.listView === "card" ? "#00BE10" : "EEEFEF"
              }}
              data-test-id="tabView"
              onClick={() => this.changeDisplayListing("card")}
            >
              <MdOutlineWindow
                fontSize={"1rem"}
                color={this.state.listView === "card" ? "#FFF" : "#000"}
              />
            </IconButton>
            <IconButton
              style={{
                backgroundColor:
                  this.state.listView === "list" ? "#00BE10" : "EEEFEF"
              }}
              data-test-id="listIcon"
              onClick={() => this.changeDisplayListing("list")}
            >
              <TbLayoutList
                fontSize={"1rem"}
                color={this.state.listView === "list" ? "#FFF" : "#000"}
              />
            </IconButton>
          </div>
          <div style={{ width: "100%" }}>
            <div
              style={
                this.state.listView === "card"
                  ? webStylesLocal.card
                  : webStylesLocal.list
              }
            >
              {this.state.wishlistList.map((item, index) => (
                <React.Fragment key={item.id}>
                  {this.state.listView === "card"
                    ? this.courseCard(item, index)
                    : this.courseList(item, index)}
                </React.Fragment>
              ))}
            </div>
            <div style={{ display: "flex", padding: "1rem" }}>
              {this.state.isLoading ? (
                <CircularProgress
                  style={{ margin: "auto", color: "#2a9e6d" }}
                />
              ) : (
                <>
                  <Button
                    data-test-id="loadMoreButtonTestId"
                    variant="contained"
                    style={{
                      background: "white",
                      borderRadius: 15,
                      margin: "auto",
                      color: "blue",
                      display: this.state.loadMoreDisabled
                        ? "none"
                        : "inline-flex"
                    }}
                    startIcon={<IoChevronDown color="black" />}
                    disabled={
                      this.state.loadMoreDisabled || this.state.isLoading
                    }
                    onClick={() => this.loadMore()}
                  >
                    Show More
                  </Button>
                </>
              )}
            </div>
          </div>
        </Box>
      </div>
    );
  }

  render() {
    return (
      <>
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        <main
          style={{
            height: "80vh",
            overflowX: "scroll"
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(180deg, rgba(60,116,129,1) 200px, rgba(255,255,255,1) 200px)",
              padding: "25px 0"
            }}
          >
            {this.renderList()}
          </div>
        </main>
      </>
    );
  }
}

// Customizable Area End
