import React from "react";

// Customizable Area Start
import { Box, Typography, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import MainLayout from "../../../components/src/MainLayout.web";
import {
  TableBody,
  TableStyle
} from "../../educational-user-profile/src/EducationalUserProfile.web";
import { PiPen } from "react-icons/pi";
// Customizable Area End

import AdminPrivacyPolicyController, {
  Props,
  PrivacyPolicy
} from "./AdminPrivacyPolicyController.web";

export default class AdminPrivacyPolicy extends AdminPrivacyPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTableBody = (item: PrivacyPolicy) => {
    return (
      <>
        <tr>
          <td style={{ textAlign: "center" }}>{item.data.id}</td>
          <td style={{ textAlign: "left", width: "500px", lineBreak: "auto" }}>
            {item.data.attributes.description}
          </td>
          <td style={{ textAlign: "left" }}>
            {item.data.attributes.created_on}
          </td>
          <td style={{ textAlign: "left" }}>
            {item.data.attributes.updated_on}
          </td>
          <td
            style={{ borderRadius: "0px 10px 10px 0px", textAlign: "center" }}
          >
            <Box className="desc_edit_icon_box">
              <PiPen
                data-test-id={"editIconTestId"}
                className="desc_edit_icon"
                onClick={() => this.navigationEditPrivacyPolicy(item.data.id)}
                size={20}
                color="black"
              />
            </Box>
          </td>
        </tr>
        <Box className="table_divider" style={webStyles.tableDivider} />
      </>
    );
  };

  renderTable = () => {
    return (
      <Box>
        <TableStyle>
          <thead>
            <tr>
              <th>
                <Box className="th_box" style={{ justifyContent: "center" }}>
                  <Typography className="title_th">S.No</Typography>
                </Box>
              </th>
              {["Description", "Created at", "Updated at"].map(header => {
                return (
                  <th>
                    <Box className="th_box" style={{ justifyContent: "left" }}>
                      <Typography className="title_th">{header}</Typography>
                    </Box>
                  </th>
                );
              })}
              <th>
                <Box className="th_box" style={{ justifyContent: "center" }}>
                  <Typography className="title_th">Action</Typography>
                </Box>
              </th>
            </tr>
          </thead>
          <span style={webStyles.dividerLine} />
          <TableBody data-test-id="policyListId">
            {this.renderTableBody(this.state.adminPrivacyPolicyData)}
          </TableBody>
        </TableStyle>
      </Box>
    );
  };

  notificationAlertpolicy = () => {
    return (
      <Snackbar
        open={this.state.isAlert}
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        onClose={this.onCloseAlert}
      >
        <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
      </Snackbar>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <MainLayout
          isLoading={this.state.loader}
          newUI
          isAdd
          title="Privacy Policy"
          data-test-id="mainLayoutEvent"
        >
          <main
            className="table_data"
            style={{ background: "rgb(252, 252, 252)" }}
          >
            {this.renderTable()}
            {this.notificationAlertpolicy()}
          </main>
        </MainLayout>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyles = {
  dividerLine: {
    height: "20px",
    display: "inline-block"
  },
  tableDivider: {
    height: "8px"
  },
  iconSize: {
    fontSize: "13px"
  }
};

// Customizable Area End
