import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}

interface PolicyAttributes {
  description: string;
  created_on: string;
  updated_on: string;
}

export interface Policy {
  data: PolicyData;
}

export interface PolicyData {
  id: string;
  type: string;
  attributes: PolicyAttributes;
}

interface ErrorResponse {
  errors: Array<{ token: string }>;
}

interface PolicyListResponse {
  data: PolicyData;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userToken: string;
  loader: boolean;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  cancellationPolicy: PolicyData | undefined;
  isUpdate: boolean;
  policyId: string | number;
  description: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class CancelattionPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPoliciesListApiCallId: string = "";
  showPolicyApiCallId: string = "";
  submitPolicyApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userToken: "",
      loader: false,
      isAlert: false,
      alertMsg: "",
      alertType: configJSON.successMsg,
      isUpdate: false,
      cancellationPolicy: undefined,
      policyId: "",
      description: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let propsData = message.getData(getName(MessageEnum.NavigationIdMessage));
      if (propsData) {
        this.handleShowPolicy(propsData.policyId);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getUserToken();
  }

  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    await this.getPoliciesList();
  };

  cancellationPolicyApiCall = (apiCallData: APIPayloadType) => {
    let { endPoint, body, method, contentType, type } = apiCallData;
    const header = {
      "Content-Type": contentType,
      token: this.state.userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? body : JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBacks = (
    apiRequestCallId: string,
    responseJson: Policy | PolicyListResponse | ErrorResponse
  ) => {
    switch (apiRequestCallId) {
      case this.getPoliciesListApiCallId:
        this.handleGetPolicyResponse(responseJson as PolicyListResponse);
        break;
      case this.showPolicyApiCallId:
        this.handleShowPolicyResponse(responseJson as Policy);
        break;
      case this.submitPolicyApiCallId:
        this.handleUpdatePolicyResponse(responseJson as ErrorResponse);
        break;
    }
  };

  handleUpdatePolicyResponse = async (response: ErrorResponse) => {
    if (
      response.errors &&
      response.errors.length > 0 &&
      response.errors[0].token === configJSON.invalidTokenMessage
    ) {
      this.setState({
        isAlert: true,
        alertMsg: "Invalid token",
        alertType: configJSON.alertTypeError,
        loader: false
      });
    } else {
      const alertMsg = configJSON.cancellationPolicyUpdateSuccessMsg;
      const alertType: Color = configJSON.alertTypeSuccess;

      this.handleFieldReset();
      this.setState(
        {
          isAlert: true,
          alertMsg,
          alertType,
          loader: false
        },
        () => {
          this.navigationCancellationPolicy();
        }
      );
    }
  };

  handleShowPolicyResponse = (response: Policy) => {
    const {
      data: {
        id,
        attributes: { description }
      }
    } = response;

    this.setState({
      loader: false,
      description: description,
      isUpdate: true,
      policyId: id
    });
  };

  handleGetPolicyResponse = (response: PolicyListResponse) => {
    this.setState({
      cancellationPolicy: response.data,
      loader: false
    });
  };

  handleDescriptionChange = (value: string) => {
    this.setState({
      description: value
    });
  };

  handleFieldReset = () => {
    this.setState({ description: "" });
  };

  onCloseAlert = () => {
    this.setState({ isAlert: false });
  };

  getBlankField = (description: string) => {
    if (!description.trimStart()) {
      return configJSON.descriptionText;
    }
    return null;
  };

  handleUpdatePolicy = async () => {
    this.setState({ loader: true });
    const { description } = this.state;
    const blankField = this.getBlankField(description);

    if (blankField) {
      this.setState({
        isAlert: true,
        alertMsg: `${blankField}${configJSON.errorMsgBlankField}`,
        alertType: configJSON.alertTypeError,
        loader: false
      });
    } else {
      const body = {
        cancellation_policy: {
          description: this.state.description
        }
      };
      this.submitPolicyApiCallId = this.cancellationPolicyApiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.examplePutAPiMethod,
        endPoint: `${configJSON.getCancellationPolicyBasicApiEndpoint}${
          this.state.policyId
        }`,
        body: body
      });
    }
  };

  handleShowPolicy = async (policyId: string | number) => {
    this.setState({ loader: true });
    const endPoint = `${configJSON.getCancellationPolicyBasicApiEndpoint}${policyId}`;
    this.showPolicyApiCallId = this.cancellationPolicyApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint
    });
  };

  getPoliciesList = async () => {
    this.setState({ loader: true });
    const endPoint = configJSON.getCancellationPolicyApiEndpoint;
    this.getPoliciesListApiCallId = this.cancellationPolicyApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint
    });
  };

  navigationEditCancellationPolicy = (policyId: string | number) => {
    const editCancellationPolicyScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationAddCancellationPolicyMessage)
    );
    editCancellationPolicyScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    editCancellationPolicyScreenMsg.addData(
      getName(MessageEnum.NavigationIdMessage),
      {
        policyId: policyId
      }
    );
    this.send(editCancellationPolicyScreenMsg);
  };

  navigationCancellationPolicy = () => {
    const cancellationPolicyScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationCancellationPolicyMessage)
    );
    cancellationPolicyScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(cancellationPolicyScreenMsg);
  };
  // Customizable Area End
}
